import React, {useState} from "react";

export const ChatContext = React.createContext({
  isOpen: false,
  toggleOpen: () => {},
  isOpenChat: false,
  toggleOpenChat: () => {},
  isOpenChannel: false,
  toggleOpenChannel: () => {},
  isOpenInfoChannel: false,
  toggleOpenInfoChannel: () => {},
  isOpenProfileChat: false,
  toggleOpenProfileChat: () => {},
  isOpenDirectory: false,
  toggleOpenDirectory: () => {},
});

export const ChatProvider = ({children}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [isOpenChannel, setIsOpenChannel] = useState(false);
  const [isOpenInfoChannel, setIsOpenInfoChannel] = useState(false);
  const [isOpenProfileChat, setisOpenProfileChat] = useState(false);
  const [isOpenDirectory, setisOpenDirectory] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);
  const toggleOpenChat = () => setIsOpenChat(!isOpenChat);
  const toggleOpenChannel = () => setIsOpenChannel(!isOpenChannel);
  const toggleOpenInfoChannel = () => setIsOpenInfoChannel(!isOpenInfoChannel);
  const toggleOpenProfileChat = () => setisOpenProfileChat(!isOpenProfileChat);
  const toggleOpenDirectory = () => setisOpenDirectory(!isOpenDirectory);

  return (
    <ChatContext.Provider
      value={{
        isOpen,
        toggleOpen,
        isOpenChat,
        toggleOpenChat,
        isOpenChannel,
        toggleOpenChannel,
        isOpenInfoChannel,
        toggleOpenInfoChannel,
        isOpenProfileChat,
        toggleOpenProfileChat,
        isOpenDirectory,
        toggleOpenDirectory,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
