import React from "react";

// default layout
import Default from "../layouts/dashboard/default";
import { useLocation, Navigate,useHistory } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ConfirmarMonthlyItinerario from "views/itinerario/ConfirmarMonthlyItinerario";
import PedidoTransfer from "views/pedido_transfer/index";


// Dashboard
const Solicitudes = React.lazy(() => import("../views/solicitudes"));
const Usuarios = React.lazy(() => import("../views/usuarios"));
const Tareas = React.lazy(() => import("../views/tareas"));
const Productos = React.lazy(() => import("../views/productos"));
const Planograma = React.lazy(() => import("../views/planograma"));
const Promoter = React.lazy(() => import("../views/promoter"));
const Itinerario = React.lazy(() => import("../views/itinerario"));
const Merchandiser = React.lazy(() => import("../views/merchandiser"));

// Carteras
const Cartera = React.lazy(() => import("views/cartera/index"));
const CarteraList = React.lazy(() => import("views/cartera/CarteraList"));
const SeleccionarPDV = React.lazy(() => import("views/cartera/SeleccionarPDV"));
const SeleccionarPDVPromoter = React.lazy(() => import("views/promoter/SeleccionarPDV"));
const ConfirmarCartera = React.lazy(() => import("views/cartera/ConfirmarCartera"));

// Promoter
const PromoterList = React.lazy(() => import("views/promoter/PromoterList"));
const SubirArchivo = React.lazy(() => import("views/promoter/SubirArchivo"));

const Epop = React.lazy(() => import("../views/epop"));
const ItinerarioList = React.lazy(() => import("views/itinerario/ItinerarioList"));
const NuevoItinerario = React.lazy(() => import("views/itinerario/NuevoItinerario"));
const SeleccionarItinerario = React.lazy(() => import("views/itinerario/SeleccionarItinerario"));
const SeleccionarItinerarioOld = React.lazy(() => import("views/itinerario/SeleccionarPdvOld"));
const Puntos = React.lazy(() => import("views/puntos/index"));
const ConfirmarItinerario = React.lazy(() => import("views/itinerario/ConfirmarItinerario"));
const EditarItinerario = React.lazy(() => import("views/itinerario/EditItinerario"));
const NuevoEPOP = React.lazy(() => import("views/epop/NuevoEpop"));
const Surtido = React.lazy(() => import("views/tareas/Surtido"));
const SolicitarSurtido = React.lazy(() => import("views/tareas/SolicitarSurtido"));
const Disponibilidad = React.lazy(() => import("views/tareas/Disponibilidad"));
const ShareOfShelf = React.lazy(() => import("views/tareas/ShareOfShelf"));
const SolicitarShareOfShelf = React.lazy(() => import("views/tareas/SolicitarShareOfShelf"));
const SolicitarDisponibilidad = React.lazy(() => import("views/tareas/SolicitarDisponibilidad"));
const Pricing = React.lazy(() => import("views/tareas/Pricing"));
const SolicitarPricing = React.lazy(() => import("views/tareas/SolicitarPricing"));
const Hallazgos = React.lazy(() => import("views/hallazgos"));
const NuevoHallazgo = React.lazy(() => import("views/hallazgos/NuevoHallazgo"));
const CrearHallazgo = React.lazy(() => import("views/hallazgos/CrearHallazgo"));
const PlanogramaList = React.lazy(() => import("views/planograma/PlanogramaList"));
const AsignarPlanograma = React.lazy(() => import("views/planograma/AsignarPlanograma"));
const SeleccionarCadenaPlanograma = React.lazy(() => import("views/planograma/SeleccionarCadenaPlanograma"));
const NuevoPromoter = React.lazy(() => import("views/promoter/NuevoPromoter"));
const SubirArchivoPlanograma = React.lazy(() => import("views/planograma/SubirArchivoPlanograma"));
const EpopList = React.lazy(() => import("views/epop/EpopList"));
const SeleccionarCadenaEpop = React.lazy(() => import("views/epop/SeleccionarCadenaEpop"));
const SubirArchivoEpop = React.lazy(() => import("views/epop/SubirArchivoEpop"));
const Reportes = React.lazy(() => import("views/reportes"));
const HallazgosList = React.lazy(() => import("views/hallazgos/HallazgosList"));
const ProductoList = React.lazy(() => import("views/productos/ProductoList"));
const ProductoTransfer = React.lazy(() => import("views/producto_transfer/index"));
const NuevoProducto = React.lazy(() => import("views/productos/NuevoProducto"));
const CategoriaHallazgosList = React.lazy(() => import("views/hallazgos/CategoriaHallazgoList"));
const EpopMerchanList = React.lazy(() => import("views/epop/EpopMerchanList"));
const EpopPuntos = React.lazy(() => import("views/epop/EpopPuntos"));
const StockDeposito = React.lazy(() => import("views/tareas/StockDeposito"));
const SolicitarStockDeposito = React.lazy(() => import("views/tareas/SolicitarStockDeposito"));
const StockGondola = React.lazy(() => import("views/tareas/StockGondola"));
const SolicitarStockGondola = React.lazy(() => import("views/tareas/SolicitarStockGondola"));
const ExhibidorList = React.lazy(() => import('views/exhibidor/ExhibidorList'));
const ExhibidorSubirArchivo = React.lazy(() => import('views/exhibidor/SubirArchivoExhibidor'));

export const DefaultRouter = [
  {
    path: "/",
    element: (
      <RequireAuth>
        <Default />
      </RequireAuth>
    ),
    children: [
      {
        index:true,
        element: (
          <RequireAuth>
            <Reportes pageName="Dashboard" />
          </RequireAuth>
        ),
      },
      {
        path: "solicitudes",
        element: (
          <RequireAuth>
            <Solicitudes pageName="Solicitudes" />
          </RequireAuth>
        ),
      },
      {
        path: "solicitudes_transfer",
        element: (
          <RequireAuth>
            <PedidoTransfer pageName="Solicitudes Transfer" />
          </RequireAuth>
        ),
      },
      {
        path: "cartera",
        element: (
          <RequireAuth>
            <Cartera />
          </RequireAuth>
        ),
        children: [
          {
            path: "",
            element: (
              <RequireAuth>
                <Merchandiser pageName="Merchandisers" from="cartera" />
              </RequireAuth>
            ),
          },
          {
            path: ":merchandiserId",
            element: (
              <RequireAuth>
                <CarteraList pageName="Cartera" />
              </RequireAuth>
            ),
          },
          {
            path: ":merchandiserId/seleccionar-pdvs",
            element: (
              <RequireAuth>
                <SeleccionarPDV pageName="Editar Cartera" />
              </RequireAuth>
            ),
          },
          {
            path: ":merchandiserId/seleccionar-pdvs/confirmar-cartera",
            element: (
              <RequireAuth>
                <ConfirmarCartera pageName="Editar Cartera" />
              </RequireAuth>
            ),
          },
        ],
      },

      {
        path: "itinerario",
        element: (
          <RequireAuth>
            <Itinerario pageName="Itinerario" />
          </RequireAuth>
        ),
        children: [
          {
            path: "",
            element: (
              <RequireAuth>
                <ItinerarioList pageName="Itinerarios" />
              </RequireAuth>
            ),
          },
          {
            path: "editarItinerario",
            element: (
              <RequireAuth>
                <EditarItinerario pageName="Editar Itinerario" />
              </RequireAuth>
            ),
          },
          {
            path: "nuevo-itinerario",
            element: (
              <RequireAuth>
                <NuevoItinerario pageName="Planificar Itinerario" />
              </RequireAuth>
            ),
          },
          {
            path: "nuevo-itinerario/seleccionar-pdvs",
            element: (
              <RequireAuth>
                <SeleccionarItinerario pageName="Seleccionar Puntos" />
              </RequireAuth>
            ),
          },
          {
            path: "nuevo-itinerario/seleccionarpdvs",
            element: (
              <RequireAuth>
                <SeleccionarItinerarioOld pageName="Seleccionar Puntos" />
              </RequireAuth>
            ),
          },
          
          {
            path: "nuevo-itinerario/seleccionar-pdvs/confirmar",
            element: (
              <RequireAuth>
                <ConfirmarMonthlyItinerario pageName="Planificar Itinerario" />
              </RequireAuth>
            ),
          },
          {
            path: "nuevo-itinerario/seleccionarpdvs/confirmar",
            element: (
              <RequireAuth>
                <ConfirmarMonthlyItinerario pageName="Nuevo Itinerario" />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "promoter",
        element: (
          <RequireAuth>
            <Promoter />
          </RequireAuth>
        ),
        children: [
          {
            path: "",
            element: (
              <RequireAuth>
                <PromoterList pageName="Promoter" />
              </RequireAuth>
            ),
          },
          {
            path: "nuevo-promoter",
            element: (
              <RequireAuth>
                <NuevoPromoter pageName="Nuevo Promoter" />
              </RequireAuth>
            ),
          },
          {
            path: "nuevo-promoter/seleccionar-cadena",
            element: (
              <RequireAuth>
                <SeleccionarPDVPromoter pageName="Nuevo Promoter" />
              </RequireAuth>
            ),
          },
          {
            path: "nuevo-promoter/seleccionar-cadena/subir-archivo",
            element: (
              <RequireAuth>
                <SubirArchivo pageName="Nuevo Promoter" />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "",
        element: (
          <RequireAuth>
            <Epop pageName="Materia POP" />
          </RequireAuth>
        ),
        children: [
          {
            path: "epop",
            element: (
              <RequireAuth>
                <EpopList pageName="Materia POP" />
              </RequireAuth>
            ),
          },
          {
            path: "epop/nuevo-epop",
            element: (
              <RequireAuth>
                <NuevoEPOP pageName="Nuevo Materia POP" />
              </RequireAuth>
            ),
          },
          {
            path: "epop/nuevo-epop/seleccionar-cadena",
            element: (
              <RequireAuth>
                <SeleccionarCadenaEpop pageName="Nuevo Materia POP" />
              </RequireAuth>
            ),
          },
          {

            path: "epop/subir",
            element: (
              <RequireAuth>
                <SubirArchivoEpop pageName="Nuevo Materia POP" />
              </RequireAuth>
            ),
          },
          {
            path: "epop/merchandiser",
            element: (
              <RequireAuth>
                <EpopMerchanList pageName="Materia POP Merchandiser" />
              </RequireAuth>
            ),
          },
          {
            path: "epop/merchandiser/puntos",
            element: (
              <RequireAuth>
                <EpopPuntos pageName="Materia POP Merchandiser" />
              </RequireAuth>
            ),
          },
          {
            path: "exhibidores",
            element: (
              <RequireAuth>
                <ExhibidorList pageName="Exhibidores" />
              </RequireAuth>
            ),
          },
          {
            path: "exhibidores/subir-archivo",
            element: (
              <RequireAuth>
                <ExhibidorSubirArchivo pageName="Subir Exhibidor" />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "epop/planograma",
        element: (
          <RequireAuth>
            <Planograma pageName="Planograma" />
          </RequireAuth>
        ),
        children: [
          {
            path: "",
            element: (
              <RequireAuth>
                <PlanogramaList pageName="Planogramas" />
              </RequireAuth>
            ),
          },
          {
            path: "seleccionar-cadena/asignar",
            element: (
              <RequireAuth>
                <AsignarPlanograma pageName="Crear planograma" />
              </RequireAuth>
            ),
          },
          {
            path: "seleccionar-cadena",
            element: (
              <RequireAuth>
                <SeleccionarCadenaPlanograma pageName="Crear planograma" />
              </RequireAuth>
            ),
          },
          {
            path: "seleccionar-cadena/asignar/subir",
            element: (
              <RequireAuth>
                <SubirArchivoPlanograma pageName="Crear planograma" />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "epop/hallazgos",
        element: (
          <RequireAuth>
            <Hallazgos pageName="Hallazgos" />
          </RequireAuth>
        ),
        children: [
          {
            path: "",
            element: (
              <RequireAuth>
                <CategoriaHallazgosList pageName="Categorias" />
              </RequireAuth>
            ),
          },
          {
            path: "categoria_hallazgo",
            element: (
              <RequireAuth>
                <HallazgosList pageName="Hallazgos" />
              </RequireAuth>
            ),
          },
          {
            path: "nuevo-hallazgo",
            element: (
              <RequireAuth>
                <NuevoHallazgo pageName="Nuevo hallazgo" />
              </RequireAuth>
            ),
          },
          {
            path: "nuevo-hallazgo/crear-hallazgo",
            element: (
              <RequireAuth>
                <CrearHallazgo pageName="Crear hallazgo" />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "tareas",
        element: (
          <RequireAuth>
            <Tareas pageName="Tareas" />
          </RequireAuth>
        ),
        children: [
          {
            path: "surtido",
            element: (
              <RequireAuth>
                <Surtido pageName="Exhibidores" />
              </RequireAuth>
            ),
          },
          {
            path: "surtido/solicitar-surtido",
            element: (
              <RequireAuth>
                <SolicitarSurtido pageName="Agregar Exhibidor" />
              </RequireAuth>
            ),
          },
          {
            path: "disponibilidad",
            element: (
              <RequireAuth>
                <Disponibilidad pageName="Disponibilidad" />
              </RequireAuth>
            ),
          },
          {
            path: "disponibilidad/solicitar-disponibilidad",
            element: (
              <RequireAuth>
                <SolicitarDisponibilidad pageName="Editar disponibilidad" />
              </RequireAuth>
            ),
          },
          {
            path: "share-of-shelf",
            element: (
              <RequireAuth>
                <ShareOfShelf pageName="Share Of Shelf" />
              </RequireAuth>
            ),
          },
          {
            path: "share-of-shelf/solicitar-share-of-shelf",
            element: (
              <RequireAuth>
                <SolicitarShareOfShelf pageName="Editar Share Of Shelf" />
              </RequireAuth>
            ),
          },
          {
            path: "pricing",
            element: (
              <RequireAuth>
                <Pricing pageName="Pricing" />
              </RequireAuth>
            ),
          },
          {
            path: "pricing/solicitar-pricing",
            element: (
              <RequireAuth>
                <SolicitarPricing pageName="Editar pricing" />
              </RequireAuth>
            ),
          },
          {
            path: "stock-deposito",
            element: (
              <RequireAuth>
                <StockDeposito pageName="Stock en Deposito" />
              </RequireAuth>
            ),
          },
          {
            path: "stock-gondola",
            element: (
              <RequireAuth>
                <StockGondola pageName="Stock en Exhibición" />
              </RequireAuth>
            ),
          },
          {
            path: "stock-deposito/solicitar-stock-deposito",
            element: (
              <RequireAuth>
                <SolicitarStockDeposito pageName="Editar Stock en Deposito" />
              </RequireAuth>
            ),
          },
          {
            path: "stock-gondola/solicitar-stock-gondola",
            element: (
              <RequireAuth>
                <SolicitarStockGondola pageName="Editar Stock en Exhibición" />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "productos",
        element: (
          <RequireAuth>
            <Productos pageName="Productos" />
          </RequireAuth>
        ),
        children: [
          {
            path: "",
            element: (
              <RequireAuth>
                <ProductoList pageName="Productos" />
              </RequireAuth>
            ),
          },
          {
            path: "nuevo-producto",
            element: (
              <RequireAuth>
                <NuevoProducto pageName="Nuevo Productos" />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "usuarios",
        element: (
          <RequireAuth>
            <Usuarios pageName="Usuarios" />
          </RequireAuth>
        ),
      },
      {
        path: "merchans",
        element: (
          <RequireAuth>
            <Merchandiser pageName="Merchandiser" from="merchans"  />
          </RequireAuth>
        ),
      },
      {
        path: "puntos",
        element: (
          <RequireAuth>
            <Puntos pageName="Puntos" />
          </RequireAuth>
        ),
      },
      {
        path: "producto_transfer",
        element: (
          <RequireAuth>
            <ProductoTransfer pageName="Productos Transfer" />
          </RequireAuth>
        ),
      },
    ],
  },
];
function RequireAuth({ children }) {
  let auth = secureLocalStorage.getItem("user")?.info?.user;
  let location = useLocation();
  if (!auth) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }
  return children;
}
