import React, { Fragment, useState } from "react";

// React Bootstrap
import { Button } from "react-bootstrap";

// React Router Dom
import { Link, useNavigate } from "react-router-dom";
import { sentItinerario } from "../../dataservice/ItinerarioDataService";

const ColumnFooter = (props) => {
  const {
    userId,
    currentFooterType,
    addressLink,
    bottomLeftContent,
    changeFooterTypeTo,
    onSubmit,
    loading,
    puntos,
    fechas,
    merchandiser,
  } = props;
  const navigate = useNavigate();
  const [currFooterType, setCurrFooterType] = useState(currentFooterType);
  
  const handleBtnClick = () => {
    setCurrFooterType(changeFooterTypeTo);
    if (currFooterType === changeFooterTypeTo) {
      if (onSubmit) {
        onSubmit();
        return;
      }
      navigate(addressLink);
    }
    // setCurrFooterType(currFooterType);
    props.onEvent(true);
  };
  return (
    <Fragment>
      {currFooterType === 1 && (
        <div
          className="column-footer d-flex justify-content-between align-items-center"
          style={{
            zIndex: "200",
            position: "absolute",
            bottom: "0",
            left: "0",
            right: "0",
            padding: "16px 16px",
            background: "#F4F7FE",
            border: "1px solid #9DBCF9",
            borderRadius: "1px 1px 7.5px 7.5px",
          }}
        >
          <p className="" style={{ color: "#707070", margin: "0" }}>
            {props.text}{" "}
            <strong style={{ color: "#000ECE" }}>{props.assignedTo}</strong>
          </p>
          
          <Button
            variant="primary rounded-pill"
            style={{ paddingLeft: "4rem", paddingRight: "4rem" }}
            onClick={(e) => handleBtnClick(e)}
          >
            {!loading ? (props.btnText)
                      :(<div><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span class="visually-hidden">Loading...</span></div>)}
          </Button>
        </div>
      )}

{currFooterType === 2 && (
        <div
          className="column-footer d-flex justify-content-center align-items-center"
          style={{
            zIndex: "200",
            position: "absolute",
            bottom: "0",
            left: "0",
            right: "0",
            backgroundColor: "red",
            padding: "16px 16px",
            background: "#F4F7FE",
          }}
        >
          <Link
            style={{ width: "100%" }}
            to={currFooterType === changeFooterTypeTo ? addressLink : ""}
            state={{
              puntosPassed: props.selectedPuntos,
              merchandiser: props.selectedMerchandiser,
            }}
          >
            <Button
              variant="outline-primary rounded-pill filter-btn"
              className="w-100"
              style={{
                borderColor: "#000ECE",
                background: "white",
                padding: "14px 4rem",
                color: "#000ECE",
                fontWeight: "bold",
              }}
              onClick={handleBtnClick}
            >
              {props.btnText}
            </Button>
          </Link>
        </div>
      )}

  {currFooterType === 3 && (
        <div
          className="column-footer d-flex justify-content-between align-items-center"
          style={{
            zIndex: "200",
            position: "absolute",
            bottom: "0",
            left: "0",
            right: "0",
            backgroundColor: "red",
            padding: "16px 16px",
            background: "#F4F7FE",
          }}
        >
          {props.bottomLeftContent}
          {addressLink && <Link
            to={addressLink}
            state={{
              puntosPassed: props.selectedPuntos,
              merchandiser: props.selectedMerchandiser,
            }}
          >
            <Button
              variant="outline-primary rounded-pill filter-btn"
              style={{ paddingLeft: "4rem", paddingRight: "4rem" }}
              onClick={handleBtnClick}
            >
              <strong>{props.btnText}</strong>
            </Button>
          </Link>}
          {!addressLink && <Button
              variant="outline-primary rounded-pill filter-btn"
              style={{ paddingLeft: "4rem", paddingRight: "4rem" }}
              onClick={handleBtnClick}
            ><strong>{props.btnText}</strong></Button>}
        </div>
      )}
      <div></div>
    </Fragment>
  );
};

export default ColumnFooter;
