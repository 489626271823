import { composeWithDevTools } from "redux-devtools-extension";
import { combineReducers,applyMiddleware,createStore } from "redux";
import settingReducer from './setting/reducers';
import thunkMiddleware from 'redux-thunk'
import reducer from "./setting/datareducer";
import promoterreducer from "./setting/promoterreducer";
import hallazgoreducer from "./setting/hallazgoreducer";

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));
const rootReducer  = combineReducers({
    appDatos: reducer,
    promoterDatos : promoterreducer,
    hallazgoDatos: hallazgoreducer,
    setting: settingReducer
});

export const store = createStore(rootReducer,composedEnhancer);
