import { useState, useContext, memo, Fragment, useEffect } from "react";

// React-bootstrap
import {
  Nav,
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";

// Router
import { Link, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

// Image
import alisterIcon from "../../../../../../src/assets/images/auth/mambo-icon-1.png";
import alisterIconText from "../../../../../../src/assets/images/auth/mamboLogo.png";
import { showPedidos } from "dataservice/SolicitudDataService";
function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );
  
  const isCurrentEventKey = activeEventKey === eventKey;
  
  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}
const Ecommercevarticalnav = memo(() => {
  const [activeMenu, setActiveMenu] = useState(false);
  let location = useLocation();
  let navigate = useNavigate();
  const signOut = async () => {
    secureLocalStorage.clear();
    navigate("/sign-in");
  };
  const [totalPedidos, setTotalPedidos] = useState(0);
  useEffect(()=>{
    leerPedidos()
  },[])
  const leerPedidos = async () =>{
    let response = await showPedidos(null, 12, 0)
    if(response?.data?.total > 0 ){
      setTotalPedidos(response.data?.total);
    }
  }
  return (
    <Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <Nav.Item
          as="li"
          className={`${location.pathname === "/" ? "active" : ""}`}
        >
          <Link style={{paddingTop:"0.3rem"}}
            className={`${
              location.pathname === "/" ? "active" : ""
            } nav-link`}
            to="/"
          >
            <span className="item-name">Dashboard</span>
          </Link>
        </Nav.Item>
        <Nav.Item
          as="li"
          className={`${
            location.pathname === "/cartera" ||
            location.pathname === "/cartera/nueva-cartera"
              ? "active"
              : ""
          }`}
        >
          <Link style={{paddingTop:"0.3rem"}}
            className={`${
              location.pathname === "/cartera" ||
              location.pathname === "/cartera/nueva-cartera"
                ? "active"
                : ""
            } nav-link`}
            to="/cartera"
          >
            <span className="item-name">Cartera</span>
          </Link>
        </Nav.Item>
        <Nav.Item as="li" className={`${location.pathname === "/itinerario" ||
            location.pathname === "/itinerario/nuevo-itinerario" || location.pathname === "/itinerario/nuevo-itinerario/seleccionarpdvs"  ||
            location.pathname === "/itinerario/nuevo-itinerario/seleccionarpdvs/confirmar" 
              ? "active"
              : ""} nav-link`}>
          <Link style={{paddingTop:"0rem",paddingBottom:"0rem"}} to="/itinerario" className={`${location.pathname === "/itinerario"  ||
            location.pathname === "/itinerario/nuevo-itinerario" || location.pathname === "/itinerario/nuevo-itinerario/seleccionarpdvs"  ||
            location.pathname === "/itinerario/nuevo-itinerario/seleccionarpdvs/confirmar" 
              ? "active"
              : ""} nav-link`}>
            <span className="item-name">Itinerario</span>
          </Link>
        </Nav.Item>
        <Nav.Item as="li" className={`${location.pathname === "/promoter" || location.pathname === "/promoter/nuevo-promoter" || 
                            location.pathname === "/promoter/nuevo-promoter/seleccionar-cadena" || 
                            location.pathname === "/promoter/nuevo-promoter/seleccionar-cadena/subir-archivo" 
              ? "active"
              : ""} nav-link`}>
          <Link style={{paddingTop:"0rem",paddingBottom:"0rem"}} className={`${location.pathname === "/promoter" || location.pathname === "/promoter/nuevo-promoter" || 
                            location.pathname === "/promoter/nuevo-promoter/seleccionar-cadena" || 
                            location.pathname === "/promoter/nuevo-promoter/seleccionar-cadena/subir-archivo" 
              ? "active"
              : ""} nav-link`} to="/promoter">
            <span className="item-name">Promoter</span>
          </Link>
        </Nav.Item>
        <Nav.Item as="li" className={`${location.pathname === "/puntos"
              ? "active"
              : ""} nav-link`}>
          <Link style={{paddingTop:"0rem",paddingBottom:"0rem"}} className={`${location.pathname === "/puntos"
              ? "active"
              : ""} nav-link`} to="/puntos">
            <span className="item-name">Puntos</span>
          </Link>
        </Nav.Item>
        <Accordion.Item
          as="li"
          bsPrefix={`nav-item  ${
            location.pathname === "/epop"
            || location.pathname === "/epop/subir"
              ? "active"
              : "" || location.pathname === "/epop/hallazgos"
              || location.pathname === "/epop/hallazgos/nuevo-hallazgo" 
              || location.pathname === "/epop/hallazgos/nuevo-hallazgo/crear-hallazgo"
              ? "active"
              : "" || location.pathname === "/epop/planograma"
              || location.pathname === "/epop/planograma/seleccionar-cadena" 
              || location.pathname === "/epop/planograma/seleccionar-cadena/asignar"
              || location.pathname === "/epop/planograma/seleccionar-cadena/asignar/subir"

              ? "active"
              : "" || location.pathname === "/"
              ? "active"
              : ""
          } `}
        >
          <CustomToggle
            eventKey="sidebar-e-commerce-1"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <span className="item-name">EPOP</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-e-commerce-1">
            <ul className="sub-nav ">
              <Nav.Item as="li" className={`${location.pathname === "/epop/hallazgos" 
              || location.pathname === "/epop/hallazgos/nuevo-hallazgo" 
              || location.pathname === "/epop/hallazgos/nuevo-hallazgo/crear-hallazgo"

              ? "active"
              : ""} nav-link`}>
                <Link style={{paddingTop:"0rem",paddingBottom:"0rem"}} className={`${location.pathname === "/epop/hallazgos" 
                || location.pathname === "/epop/hallazgos/nuevo-hallazgo" 
                || location.pathname === "/epop/hallazgos/nuevo-hallazgo/crear-hallazgo"
              ? "active"
              : ""} nav-link`} to="/epop/hallazgos">
                  <span className="item-name">Hallazgos</span>
                </Link>
              </Nav.Item>
              <Nav.Item as="li" className={`${location.pathname === "/epop/planograma"
              || location.pathname === "/epop/planograma/seleccionar-cadena" 
              || location.pathname === "/epop/planograma/seleccionar-cadena/asignar"
              || location.pathname === "/epop/planograma/seleccionar-cadena/asignar/subir"

              ? "active"
              : ""} nav-link`}>
                <Link style={{paddingTop:"0rem",paddingBottom:"0rem"}} className={`${location.pathname === "/epop/planograma"
                || location.pathname === "/epop/planograma/seleccionar-cadena" 
                || location.pathname === "/epop/planograma/seleccionar-cadena/asignar"
                || location.pathname === "/epop/planograma/seleccionar-cadena/asignar/subir"
              ? "active"
              : ""} nav-link`} to="/epop/planograma">
                  <span className="item-name">Planograma</span>
                </Link>
              </Nav.Item>
              <Nav.Item as="li" className={`${location.pathname === "/epop" || location.pathname === "/epop/subir"
              ? "active"
              : ""} nav-link`}>
                <Link style={{paddingTop:"0rem",paddingBottom:"0rem"}} className={`${location.pathname === "/epop"
              ? "active"
              : ""} nav-link`} to="/epop">
                  <span className="item-name">Material POP</span>
                </Link>
              </Nav.Item>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        <Accordion.Item
          as="li"
          bsPrefix={`nav-item  ${
            location.pathname === "/tareas"
              ? "active"
              : "" || location.pathname === "/tareas/surtido"
              ? "active"
              : "" || location.pathname === "/tareas/disponibilidad"
              ? "active"
              : "" || location.pathname === "/tareas/shareOfShare"
              ? "active"
              : "" || location.pathname === "/tareas/pricing"
              ? "active"
              : "" || location.pathname === "/tareas/stock-deposito"
          } `}
        >
          <CustomToggle
            eventKey="sidebar-e-commerce"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <span className="item-name">Tareas</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-e-commerce">
            <ul className="sub-nav ">
              <Nav.Item as="li" className={`${location.pathname === "/tareas/surtido"
              ? "active"
              : ""} nav-link`}>
                <Link style={{paddingTop:"0rem",paddingBottom:"0rem"}} className={`${location.pathname === "/tareas/surtido"
              ? "active"
              : ""} nav-link`} to="/tareas/surtido">
                  <span className="item-name">Exhibidores</span>
                </Link>
              </Nav.Item>
              <Nav.Item as="li" className={`${location.pathname === "/tareas/disponibilidad"
              ? "active"
              : ""} nav-link`}>
                <Link style={{paddingTop:"0rem",paddingBottom:"0rem"}} className={`${location.pathname === "/tareas/disponibilidad"
              ? "active"
              : ""} nav-link`} to="/tareas/disponibilidad">
                  <span className="item-name">Disponibilidad</span>
                </Link>
              </Nav.Item>
              <Nav.Item as="li" className={`${location.pathname === "/tareas/share-of-shelf"
              ? "active"
              : ""} nav-link`}>
                <Link style={{paddingTop:"0rem",paddingBottom:"0rem"}} className={`${location.pathname === "/tareas/share-of-shelf"
              ? "active"
              : ""} nav-link`} to="/tareas/share-of-shelf">
                  <span className="item-name">Share of Shelf</span>
                </Link>
              </Nav.Item>
              <Nav.Item as="li" className={`${location.pathname === "/tareas/pricing"
              ? "active"
              : ""} nav-link`}>
                <Link style={{paddingTop:"0rem",paddingBottom:"0rem"}} className={`${location.pathname === "/tareas/pricing"
              ? "active"
              : ""} nav-link`} to="/tareas/pricing">
                  <span className="item-name">Pricing</span>
                </Link>
              </Nav.Item>
                <Nav.Item as="li" className={`${location.pathname === "/tareas/stock-deposito"
              ? "active"
              : ""} nav-link`}>
                <Link style={{paddingTop:"0rem",paddingBottom:"0rem"}} className={`${location.pathname === "/tareas/stock-deposito"
              ? "active"
              : ""} nav-link`} to="/tareas/stock-deposito">
                  <span className="item-name">Stock en Deposito</span>
                </Link>
              </Nav.Item>
                <Nav.Item as="li" className={`${location.pathname === "/tareas/stock-gondola"
              ? "active"
              : ""} nav-link`}>
                <Link style={{paddingTop:"0rem",paddingBottom:"0rem"}} className={`${location.pathname === "/tareas/stock-gondola"
              ? "active"
              : ""} nav-link`} to="/tareas/stock-gondola">
                  <span className="item-name">Stock en Exhibición</span>
                </Link>
              </Nav.Item>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        <li>
          <hr className="hr-horizontal" />
        </li>
        <Nav.Item as="li" className={`${location.pathname === "/productos" || location.pathname === "/productos/nuevo-producto"
              ? "active"
              : ""} nav-link`}>
          <Link style={{paddingTop:"0.1rem"}} className={`${location.pathname === "/productos" || location.pathname === "/productos/nuevo-producto"
              ? "active"
              : ""} nav-link`} to="/productos">
            <span className="item-name">Productos</span>
          </Link>
        </Nav.Item>
        <Nav.Item as="li" className={`${location.pathname === "/producto_transfer" 
              ? "active"
              : ""} nav-link`}>
          <Link style={{paddingTop:"0.1rem"}} className={`${location.pathname === "/producto_transfer" 
              ? "active"
              : ""} nav-link`} to="/producto_transfer">
            <span className="item-name">Productos Transfer</span>
          </Link>
        </Nav.Item>
        <Nav.Item as="li" className={`${location.pathname === "/exhibidores" || location.pathname === "/exhibidores"
              ? "active"
              : ""} nav-link`}>
          <Link style={{paddingTop:"0.1rem"}} className={`${location.pathname === "/exhibidores" || location.pathname === "/exhibidores"
              ? "active"
              : ""} nav-link`} to="/exhibidores">
            <span className="item-name">Exhibidores</span>
          </Link>
        </Nav.Item>
        <Nav.Item
          as="li"
          className={`${location.pathname === "/solicitudes" ? "active" : ""}`}
        >
          <Link style={{paddingTop:"0.1rem"}}
            className={`${
              location.pathname === "/solicitudes" ? "active" : ""
            } nav-link`}
            to="/solicitudes"
          >
            <span className="item-name">Solicitudes</span>
            {totalPedidos > 0 ? (
              <div>
                  <span className="badge bg-warning rounded-pill d-inline-block">
                  {totalPedidos}
                  </span>
              </div>
            ) : (
              null
            )}
          </Link>
        </Nav.Item>
        <Nav.Item
          as="li"
          className={`${location.pathname === "/solicitudes_transfer" ? "active" : ""}`}
        >
          <Link style={{paddingTop:"0.1rem"}}
            className={`${
              location.pathname === "/solicitudes_transfer" ? "active" : ""
            } nav-link`}
            to="/solicitudes_transfer"
          >
            <span className="item-name">Solicitudes Transfer</span>
            {/* {totalPedidos > 0 ? (
              <div>
                  <span className="badge bg-warning rounded-pill d-inline-block">
                  {totalPedidos}
                  </span>
              </div>
            ) : (
              null
            )} */}
          </Link>
        </Nav.Item>
        <Nav.Item as="li" className={`${location.pathname === "/merchans"
              ? "active"
              : ""} nav-link`}>
          <Link style={{paddingTop:"0rem"}} className={`${location.pathname === "/merchans"
              ? "active"
              : ""} nav-link`} to="/merchans">
            <span className="item-name">Merchans</span>
          </Link>
        </Nav.Item>

        <li>
          <hr className="hr-horizontal" />
        </li>
        <Nav.Item as="li">
          <Link style={{paddingTop:"0.1rem"}} className={`nav-link`} onClick={() => signOut()} to="/sign-in">
            <span className="item-name">Cerrar Sesión</span>
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link style={{paddingTop:"0.1rem"}} className={`nav-link`} to="https://wa.link/c2qqqy">
            <span className="item-name">Soporte</span>
          </Link>
        </Nav.Item>
        <div
          style={{
            position: "relative",
            marginLeft: "12px",
            textAlign: "center",
          }}
        >
          
        </div>
      </Accordion>
    </Fragment>
  );
});
Ecommercevarticalnav.displayName = "Ecommercevarticalnav";
export default Ecommercevarticalnav;
