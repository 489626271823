import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import {RouterProvider, createBrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./store";
import {IndexRouters} from "./router";
import {ChatProvider} from "context/chat-context";
import PageLoader from "components/PageLoader/PageLoader";

const router = createBrowserRouter([...IndexRouters], {
  basename: process.env.PUBLIC_URL,
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <ChatProvider>
      <RouterProvider router={router}>
        <App />
      </RouterProvider>
    </ChatProvider>
    <PageLoader/>
  </Provider>
);
