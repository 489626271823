import { memo, Fragment, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import Ecommercesidebar from "../../views/modules/e-commerce/components/partials/e-commerce-sidebar";
import Loader from "../../components/Loader";

import * as SettingSelector from "../../store/setting/selectors";

import { QueryClient, QueryClientProvider } from 'react-query';

const Default = memo((props) => {
  const pageLayout = useSelector(SettingSelector.page_layout);
  const appName = useSelector(SettingSelector.app_name);

  const queryClient = new QueryClient({

  })

  return (
    <Fragment>
      <Loader />
      {/* Re-name component into a default sidebar */}
      <Ecommercesidebar app_name={appName} />
      <QueryClientProvider client={queryClient}>
        <main className="main-content">
          <div className={`${pageLayout} content-inner pb-0`}>
            <Suspense fallback={<div className="react-load"></div>}>
              <Outlet></Outlet>
            </Suspense>
          </div>
        </main>
      </QueryClientProvider>
      {/* Change component name into Filter Component */}
    </Fragment>
  );
});

Default.displayName = "Default";
export default Default;
