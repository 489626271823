import apiClient from "./AxiosRequest";
export const getPedidosPendientesCall = (page) => {
    return apiClient().get('/get_pedidos_transfer?page='+page)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('getPedidosPendientesCall error: ', error.response.data);
            console.log('getPedidosPendientesCall status', error.response.status);
            console.log('getPedidosPendientesCall headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("getPedidosPendientesCall error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('getPedidosPendientesCall error', error.message);
        }
    });
}
export const updatePedido = (estado,id) => {
    return apiClient().put('/update_pedido_transfer_estado',{status:estado,pedido_transfer_id:id})
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('getPedidosPendientesCall error: ', error.response.data);
            console.log('getPedidosPendientesCall status', error.response.status);
            console.log('getPedidosPendientesCall headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("getPedidosPendientesCall error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('getPedidosPendientesCall error', error.message);
        }
    });

}
//export function to update multiple pedidos
export const updatePedidos = (data) => {
    return apiClient().post('/update_multiple_pedido_transfer_estado',{data:data})
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('updatePedidos error: ', error.response.data);
            console.log('updatePedidos status', error.response.status);
            console.log('updatePedidos headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("updatePedidos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('updatePedidos error', error.message);
        }
    });

}
//export function to get historial of pedidos
export const getHistorialPedidosCall = (page) => {
    return apiClient().get('/get_historial_pedido_transfer?page='+page)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('getHistorialPedidosCall error: ', error.response.data);
            console.log('getHistorialPedidosCall status', error.response.status);
            console.log('getHistorialPedidosCall headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("getHistorialPedidosCall error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('getHistorialPedidosCall error', error.message);
        }
    });
}
let cancelToken;
export const searchPendientes = (search,page) => {
    let request = apiClient();
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.")
    }
    cancelToken = request.CancelToken
    return request.get('/search_pedidos_transfer_pendientes/' + search + '?page='+page,{cancelToken:cancelToken.token} )
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('searchPendientes error: ', error.response.data);
            console.log('searchPendientes status', error.response.status);
            console.log('searchPendientes headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("searchPendientes error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('searchPendientes error', error.message);
        }
    });
}
export const searchHistorial = (search,page) => {
    let request = apiClient();
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.")
    }
    cancelToken = request.CancelToken
    return request.get('/search_historial_pedido_transfer/' + search + '?page='+page,{cancelToken:cancelToken.token} )
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('searchPendientes error: ', error.response.data);
            console.log('searchPendientes status', error.response.status);
            console.log('searchPendientes headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("searchPendientes error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('searchPendientes error', error.message);
        }
    });
}