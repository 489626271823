import apiClient from "./AxiosRequest";
import moment from 'moment';
export const getPuntosCall =  (page=null,filters) => {
    if(page){
        return apiClient().post('/get_all_puntos?page='+page,filters)
    }
    return apiClient().post('/get_all_puntos',filters)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}
export const newPuntosCall =  (data) => {
    let pedido = {};
    pedido.datos_json = JSON.stringify(data);
    pedido.fecha_pedido = moment().format('YYYY-MM-DD HH:mm:ss')
    pedido.tabla = 'puntos'
    pedido.tipo_pedido = 'insert'
    return apiClient().post('/pedidos',pedido)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}
export const editPuntoCall =  (puntoId,data) => {
    return apiClient().put('/puntos/' + puntoId,data)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}
export const deletePuntosCall =  (puntoId) => {
    return apiClient().delete('/puntos/' + puntoId)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}
export const deleteMultiplePuntosCall =  (puntoIds) => {
    let data = {punto_ids:puntoIds};
    return apiClient().post('/delete_multiple_puntos' , data)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}

let cancelToken;
export const searchPuntosCall =  (cancel,query,page=null) => {
    let request = apiClient();
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.")
    }
    cancelToken = request.CancelToken
    if(page){
        return apiClient().get('/search_puntos/' + query + '?page=' + page , {cancelToken:cancelToken.token})
    }
    return apiClient().get('/search_puntos/' + query , {cancelToken:cancelToken.token})
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}
export const getPuntosFiltrado =  (filters,page=null,cancel=null) => {
    let request = apiClient();
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.")
    }
    cancelToken = request.CancelToken
    if(page){
        return apiClient().post('/get_puntos_filtros?page='+page,filters,{cancelToken:cancelToken?.token})
    }
    return apiClient().post('/get_puntos_filtros', filters,{cancelToken:cancelToken?.token})
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}
//este function trae todos los puntos seleccionados con orden si tiene el start point
export const getPuntosOrdenados =  (semanas) => {
    return apiClient().post('/get_puntos_ordenados', {semanas:semanas})
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}
export const getPuntosFromIds = (ids) => {
    return apiClient().post('/puntos_from_ids', {punto_ids:ids})
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}
export const getPuntoIdsFiltradoSinPaginacion = (filters) => {
    return apiClient().post('/punto_ids_filtros', filters)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}
export const getPuntoDetails = (fields) => {
    return apiClient().post('/punto_details', fields)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('getPuntoDetails error: ', error.response.data);
            console.log('getPuntoDetails status', error.response.status);
            console.log('getPuntoDetails headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("getPuntoDetails error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('getPuntoDetails error', error.message);
        }
    });
}
export const getCampoValorForPuntoMerch = (merchandiser) => {
    return apiClient().get('/campo_valores_punto_merchan/' + merchandiser)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('getCampoValorForPuntoMerch error: ', error.response.data);
            console.log('getCampoValorForPuntoMerch status', error.response.status);
            console.log('getCampoValorForPuntoMerch headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("getPuntoDetails error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('getPuntoDetails error', error.message);
        }
    });
}
export const getCampoValorForCadena = (cadena) => {
    return apiClient().get('/campo_valores_cadena/' + cadena)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('getPuntoDetails error: ', error.response.data);
            console.log('getPuntoDetails status', error.response.status);
            console.log('getPuntoDetails headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("getPuntoDetails error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('getPuntoDetails error', error.message);
        }
    });
}
export const getCampoValores = () => {
    return apiClient().get('/campo_valores_puntos')
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('getCampoValores error: ', error.response.data);
            console.log('getCampoValores status', error.response.status);
            console.log('getCampoValores headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("getCampoValores error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('getCampoValores error', error.message);
        }
    });
}