import React, { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader,Marker,InfoWindow } from '@react-google-maps/api';

const containerStyle = { borderRadius: "16px", height: "60.4vh" }


const center = {
  lat: -23.503797481664872,
  lng: -58.201435472151196
};
function CarteraMap(props) {
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: "AIzaSyAMtbHGuf6kayLKaAnWsIkgpKfW70yUOmc"
    })
    const [map, setMap] = useState();
    const [path,setPath] = useState([]);
    const center = props.center
    const [puntos,setPuntos] = useState([])
    const [activeMarker, setActiveMarker] = useState(null);
    useEffect(()=>{
      console.log("puntos : ", props.puntos)
        if(props.puntos && props.puntos.length > 0){
            let puntos = props.puntos.map(punto=>{return {
                                            id:punto.id,
                                            nombre:punto.nombre,
                                            position:{lat:punto.lat?punto.lat:+punto.latitude,
                                                        lng:punto.lng?punto.lng:+punto.longitude},
                                            orden:punto.orden
                                            }})
            console.log(puntos)      
            setPuntos(puntos);
        }
    },[props.puntos])
    const onLoad = (map) => {
      const bounds = new window.google.maps.LatLngBounds();
      puntos?.forEach((punto) => {
        bounds.extend(new window.google.maps.LatLng(punto.position.lat, punto.position.lng))
      });
      map.fitBounds(bounds);
      setMap(map)
    };
    useEffect(()=>{
      if(map){

      const bounds = new window.google.maps.LatLngBounds();
      puntos?.forEach((punto) => {
        bounds.extend(new window.google.maps.LatLng(punto.position.lat, punto.position.lng))
      });
      map.fitBounds(bounds);
      }
    },[map,puntos])
    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
          return;
        }
        setActiveMarker(marker);
      };
    return isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={11}
          onLoad={onLoad}
        >
          { puntos.map(({id,nombre,position,orden})=>(
            <Marker key={id}
            position={position}
            label={`${orden}`}
            onClick={() => handleActiveMarker(id)}>
                {activeMarker === id ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div>{orden + ". " + nombre} </div>
                    </InfoWindow>
                ) : null}
            </Marker>
          )) }
        </GoogleMap>
    ) : <></>
  }
  export default CarteraMap