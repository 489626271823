import { useState, memo, Fragment, useEffect } from 'react'

//react-bootstrap
import { Row, Col, Image, Form, Button, InputGroup, } from 'react-bootstrap'

//router
import { Link, useNavigate } from 'react-router-dom'

//components
import Card from '../../../components/bootstrap/card'

// img
import auth1 from '../../../assets/images/auth/login1.png'
import logoMambo from '../../../assets/images/auth/mamboLogo.png'
import logoAlister from '../../../assets/images/auth/alisterLogo.png'

// Import selectors & action from setting store
import * as SettingSelector from '../../../store/setting/selectors'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';

import { login } from 'store/setting/datareducer'
import  secureLocalStorage  from  "react-secure-storage";
// import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/fontawesome.min.csss'
import {BsFillEyeFill, BsFillEyeSlashFill} from "react-icons/bs";
const SignIn = memo(() => {
   const appName = useSelector(SettingSelector.app_name)
   const dispatch = useDispatch()
   const [email,setEmail] = useState("");
   const [password, setPassword] = useState("")
   const[authError,setAuthError] = useState("");
   const [validated, setValidated] = useState(false);

   let navigate = useNavigate()
   const handleSubmit = async (event) => {
      const form = event.currentTarget;

      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
      }else{
         signIn()
      }
   }
   const signIn = async()=>{
      let data = {email:email,password:password};
      const loginFunction = await login(data);
      const user = await dispatch(loginFunction);
      if(user){
         secureLocalStorage.setItem("user", {
            info: user,
         });
         navigate('/')
      }
      setAuthError("El correo o contraseña es incorrecto");
   }
   const [visible, setVisible] = useState(false);
 
   const cambiarVisible = () => {
     setVisible(!visible);
   };
 
   const tipoInput = visible ? 'text' : 'password';
 
   return (
      <Fragment>
         <section className="login-content">
            <Row className="m-0 align-items-center vh-100" style={{backgroundColor: "#B8DAE8"}}>
               <Col md="7" className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
               <div
                  className="image-container"
                  style={{
                     backgroundImage: `url(${auth1})`,
                     backgroundPosition: 'center',
                     backgroundRepeat: 'no-repeat',
                     backgroundSize: 'cover',
                     height: '100%',
                     width: '100%'
                  }}
                  ></div>

               </Col>
               <Col md="5">
                  <Row className="justify-content-center" style={{ marginTop: '10%' }}>
                     <Col md="10">
                        <Card className="shadow-none d-flex justify-content-center mb-0 auth-card" style={{backgroundColor: "#B8DAE8"}}>
                           <Card.Body>
                              <div className="d-flex justify-content-center" >
                                       <a href="https://mambo.com.py" target="_blank" style={{ marginTop: '15%'}}>
                                          <Image src={logoAlister} width="270" height="70" alt="alister"/>
                                       </a>
                                    </div>
                              {authError && <span style={{color:'#c03221',display: 'flex', justifyContent: 'center'}}>{authError}</span>}
                              <Form noValidate validated={validated} style={{}}>
                                 <Row>
                                    <Col lg="7" style={{ marginLeft: '20%', justifyContent: 'center',  marginTop: '15%'}}>
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="email" className="">Email</Form.Label>
                                          <Form.Control 
                                             type="email" 
                                             className="" 
                                             id="email" 
                                             aria-describedby="email" 
                                             placeholder=" " 
                                             onChange={(e) => { setEmail(e.target.value) }} 
                                             required />
                                          <Form.Control.Feedback type="invalid">
                                             Favor ingrese su correo.
                                          </Form.Control.Feedback>
                                       </Form.Group >
                                    </Col>
                                    <Col lg="7" style={{ marginLeft: '20%', justifyContent: 'center' }}>
                                       <Form.Label htmlFor="password" className="">Contraseña</Form.Label>
                                       <InputGroup className="mb-3">
                                          <Form.Control type={tipoInput}
                                             className="" id="password" 
                                             aria-describedby="password" 
                                             placeholder=" " 
                                             onChange={(e) => { setPassword(e.target.value) }} 
                                             required />
                                          <InputGroup.Text>
                                             <span onClick={cambiarVisible}>
                                                {visible ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
                                             </span>
                                          </InputGroup.Text>
                                          <Form.Control.Feedback type="invalid">
                                             Favor ingrese su contraseña.
                                          </Form.Control.Feedback>
                                       </InputGroup>
                                    </Col>
                                    <Col lg="12" className="d-flex justify-content-center">
                                       <Link to="/default/auth/recoverpw">Olvidé mi contraseña</Link>
                                    </Col>
                                 </Row>
                                 
                              </Form>
                                 <div className="d-flex justify-content-center" style={{ marginTop: '17%' }}>
                                    <Button type="button"onClick={handleSubmit} style={{ paddingLeft: "6rem", paddingRight: "6rem" }} variant="primary rounded-pill">INGRESAR</Button>
                                 </div>
                           </Card.Body>
                           
                              
                           
                        </Card>
                     </Col>
                     <div className="d-flex justify-content-center" style={{ marginTop: '20%' }}>
                        <a href="https://mambo.com.py" target="_blank" style={{ marginBottom: '2%' }}>
                           <Image src={logoMambo} width="200" height="15" alt="mambo"/>
                        </a>
                     </div>
                  </Row>
               </Col>
            </Row>
            
         </section>
      </Fragment>
   )
}
)

SignIn.displayName = "SignIn"
export default SignIn
