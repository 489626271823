import React, { memo, useEffect, useRef, useState } from "react";
import { Nav, Tab } from "react-bootstrap";

const TabPane = memo((props) => {
  const { tabs, setSelectedTabId } = props;
  const [activeTab, setActiveTab] = useState();
  const firstRender = useRef(true);
  const[orderedTabs,setOrderedTabs] = useState([])
  useEffect(() => {
    if (firstRender.current && tabs?.length > 0) {
        tabs.sort((a, b) => a.orden - b.orden);
        setActiveTab(tabs[0].key);
        setSelectedTabId(tabs[0].key);
        setOrderedTabs(tabs);
        firstRender.current = false;
        return
    }
    if(tabs?.length > 0){
      tabs.sort((a, b) => a.orden - b.orden);
      setOrderedTabs(tabs);
    }
  }, [tabs]);
  const onClickTab = (tabsKey) => {
    if (setSelectedTabId && tabsKey != null) {
      setSelectedTabId(tabsKey);
    }
  };

  return (
    <Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
      <Nav variant="tabs">
        {orderedTabs?.map((tab) => (
          <Nav.Item key={tab.key}>
            <Nav.Link onClick={() => onClickTab(tab.key)} eventKey={tab.key}>
              {tab.title}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content>
        {orderedTabs?.map((tab) => (
          <Tab.Pane key={tab.key} eventKey={tab.key}>
            {tab.content}
          </Tab.Pane>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
});

export default TabPane;
