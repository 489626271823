import React from 'react'
import SignIn from '../views/dashboard/auth/sign-in'

//auth
const ConfirmMail = React.lazy(() => import("../views/dashboard/auth/confirm-mail"));
const LockScreen = React.lazy(() => import("../views/dashboard/auth/lock-screen"));
const Recoverpw = React.lazy(() => import("../views/dashboard/auth/recoverpw"));
const SignUp = React.lazy(() => import("../views/dashboard/auth/sign-up"));
// errors
const Error404 = React.lazy(() => import("../views/dashboard/errors/error404"));
const Error500 = React.lazy(() => import("../views/dashboard/errors/error500"));
const Maintenance = React.lazy(() => import("../views/dashboard/errors/maintenance"));


export const AuthRouter = [
    {
        path: '/sign-in',
        element: <SignIn />
    },
    {
        path: 'sign-up',
        element: <SignUp />
    },

    {
        path: 'default/auth/confirm-mail',
        element: <ConfirmMail />
    },
    {
        path: 'default/auth/lock-screen',
        element: <LockScreen />
    },
    {
        path: 'default/auth/recoverpw',
        element: <Recoverpw />
    },
    {
        path: 'errors/error404',
        element: <Error404 />
    },
    // Utilities
    {
        path: 'errors/error500',
        element: <Error500 />
    },
    {
        path: 'errors/maintenance',
        element: <Maintenance />
    }
]

