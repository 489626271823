import { memo, Fragment, useState, useEffect } from "react";

// React-router-dom
import { Link } from "react-router-dom";

import {GrStatusGoodSmall} from "react-icons/gr";

// React-bootstrap
import { Row, Col, Button,Spinner } from "react-bootstrap";
import {colorsObjects} from "utilities/colorsObject";

// Components
import Card from "../../../src/components/bootstrap/card";
import { useSortableTable } from "./useSortableTable";
import "./style.scss";

// SVGs
import Previous from "../../../src/assets/images/icons/Previous.svg";
import Next from "../../../src/assets/images/icons/Next.svg";
import sortIcon from "../../../src/assets/images/icons/sortIcon.svg";

import Remove from "assets/images/icons/Remove.svg";

import NumberInput from "components/NumberInput/NumberInput";
import { TextToolTip } from "utilities/TextToolTip";
const Table = memo((props) => {
  const {
    columns,
    data,
    outActionButtons,
    title,
    pagination,
    border,
    underlineFirstColumn,
    firstColumnColor,
    selectAll,
    selectAllOnFirstColumn,
    checkboxDisabled,
    onClickFirstCol,
    setIds,
    paginationLinks,
    paginateFunction,
    positionOfCenteredColumns,
    positionOfEndColumns,
    totalQty,
    perPage,
    firstCheckColumn = true,
    showNumberInput,
    setIncDecNumber,
    showDeleteLink,
    removeFunction,
    noHead = false,
    checkeado = false,
    idsChecked,
    invalidIds,
    selectOne = false,
    useSort,
    isSelectAllPages = false,
    nextLink,
    prevLink,
    setIncDecNumberTodo,
    cantidadVisitasForAll,
    idsOfAllPages
  } = props;
  const [tableData, handleSorting, setTableData] = useSortableTable(data);
  const[toBeSorted,setToBeSorted] = useState(false)
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const [IdsOfCheckedItems, setIdsOfCheckedItems] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isAllCheckedPerPage, setIsAllCheckedPerPage] = useState([]);
  const[showToolTip,setShowToolTip] = useState(false)
  const[isAllCheckedFromAllPages,setIsAllCheckedFromAllPages] = useState(false)
  useEffect(() => {
    setTableData(data.map(item=>({...item,"sortNumber":0})));
  }, [props.data, props.setIds]);
  useEffect(()=>{
    if(props.isAllCheckedFromAllPages!==undefined  ){
        setIsAllCheckedFromAllPages(props.isAllCheckedFromAllPages);
    }
  },[props.isAllCheckedFromAllPages])
  useEffect(()=>{props.toolTip?.length>0 ? setShowToolTip(true) : setShowToolTip(false)},[props.toolTip])
  const handleSortingChange = (label) => {
    const sortOrder = label === sortField && order === "asc" ? "desc" : "asc";
    setSortField(label);
    setOrder(sortOrder);
    handleSorting(label, sortOrder);
  };
  const handleSelectAll = () => {
      updateIsAllCheckedPerPage();
      setIsAllCheckedFromAllPages(false)
      props.selectAllFromAllPages && props.selectAllFromAllPages(false)
      setIsAllChecked((prevAllChecked) => !prevAllChecked);
      let idsOfCurrentPage = tableData.map((li) => li.id)
      setIdsOfCheckedItems((prevIds) => prevIds.concat(idsOfCurrentPage));
      setIds((prevIds) => prevIds.concat(idsOfCurrentPage));
      if (isAllChecked) {
        setIdsOfCheckedItems((prevIds)=> prevIds.filter(id => { 
                                                          return !idsOfCurrentPage.includes(id); 
                                                        }));
        setIds((prevIds)=> prevIds.filter(id => { 
                  return !idsOfCurrentPage.includes(id); 
                }));
            }
      return
    
  };
  const updateIsAllCheckedPerPage = () => {
    let pageSelected = paginationLinks?.find(link=>link.active==true)?.label
    let isAllCheckedPerPageCopy = [...isAllCheckedPerPage]
    isAllCheckedPerPageCopy.forEach((checkedPerPage)=>{
      if(checkedPerPage.page == pageSelected){
        checkedPerPage.isAllChecked = !checkedPerPage.isAllChecked
      }
    })
    setIsAllCheckedPerPage(isAllCheckedPerPageCopy);
  }

  useEffect(()=>{
    if(paginationLinks?.length > 0){
      let pageSelected = paginationLinks?.find(link=>link.active==true)?.label
      let isAllCheckedPerPageCopy = [...isAllCheckedPerPage]
      let updated = false;
      isAllCheckedPerPageCopy.forEach((element)=>{
        if(element.page == pageSelected){
          setIsAllChecked(element.isAllChecked)
          updated = true
        }
      })
      if(!updated){
        paginationLinks.forEach(link=>{
          isAllCheckedPerPageCopy.push({page:link.label,isAllChecked:false})
        })
        setIsAllCheckedPerPage(isAllCheckedPerPageCopy);
      }
    }
  },[paginationLinks])
  useEffect(()=>{
    if(idsChecked?.length<=0){
      setIsAllChecked(false);
      setIdsOfCheckedItems([])
      setIds && setIds([])  
      setIsAllCheckedPerPage([])
    }
  },[totalQty])
  const checkboxClickHandler = (e) => {
    const { id, checked } = e.target;
    if(checked){
      if (selectOne == true){
        setIdsOfCheckedItems([id]);
        setIds(id);
        return
      }
      setIdsOfCheckedItems([...IdsOfCheckedItems, +id]);
      setIds([...IdsOfCheckedItems, +id]);
      return
    }
    if (!checked) {
      setIdsOfCheckedItems(IdsOfCheckedItems.filter((item) => item !== +id));
      setIds(IdsOfCheckedItems.filter((item) => item !== +id));
    }
  };
  useEffect(()=>{
    if (tableData.length != 0 ){
      if(checkeado === true || isAllCheckedFromAllPages){
        setIdsOfCheckedItems(tableData.map((li) => li.id));
        setIds(prevIds=>[...new Set(prevIds.concat(tableData.map((li) => li.id)))]);
        setIsAllChecked(true);
        return
      }
      setIsAllChecked(false)
    }
  },[tableData])
  useEffect(()=>{
    if(toBeSorted && useSort){
      handleSorting("sortNumber","desc")
      setToBeSorted(false)
    }
  },[toBeSorted,tableData])
  useEffect(()=>{
    if(idsChecked){
      setIdsOfCheckedItems([...idsChecked])
      setIds([...idsChecked])
    }
  },[idsChecked])
  useEffect(()=>{
    if(useSort){
      let tableDataCopy = tableData.map(item=>{
        if(IdsOfCheckedItems.includes(item.id)){
          return {...item,"sortNumber":1}
        }
        return {...item,"sortNumber":0}
      })
      setTableData(tableDataCopy)
      setToBeSorted(true)
    }
  },[IdsOfCheckedItems])
  const esNumero = (value) =>  {
   
    if (typeof value == 'number' || typeof value == 'object' || value == undefined) {
      return true
    }else if (typeof value == "string" && !isNaN(value) && !isNaN(parseFloat(value))) {
      return true
    } else {
      return false
    }
  }
  useEffect(()=>{
    if(idsOfAllPages){
      setIdsOfCheckedItems((prevIds) =>[...new Set(prevIds.concat(idsOfAllPages))]);
    }
  },[idsOfAllPages])
  const rowCheckBox = (index,item) => {
    let numericIds = IdsOfCheckedItems.map(id=>parseInt(id))
    return index === 0 && selectAllOnFirstColumn ? 
      <input
        className="form-check-input my-auto border border-primary"
        type="checkbox"
        name="selectAll"
        id="selectAll"
        onChange={handleSelectAll}
        checked={isAllChecked}
        style={checkboxDisabled?{pointerEvents : 'none'}:{}}
        disabled={checkboxDisabled}
        key={`checkBox-${item.id}`}
      />
    : 
      <input
        className="form-check-input my-auto border border-primary"
        type="checkbox"
        name="option-{item.id}"
        id={item.id}
        onChange={checkboxClickHandler}
        checked={numericIds?.includes(item.id)}
        style={checkboxDisabled?{pointerEvents : 'none'}:{}}
        disabled={checkboxDisabled}
        key={`checkBox-${item.id}`}
      />
    
  }
 
  return (
    <Fragment>
      {outActionButtons ? (
        <div className="d-flex justify-content-between mt-5 align-items-center mb-3">
          <div className="d-flex align-items-center my-auto">
            <h5 className="mb-0">
              { perPage ? perPage + " de " + totalQty : " "} {title}
            </h5>
          </div>
          <div className="d-flex justify-content-evenly">
            {outActionButtons?.map((item, index) => {
              return <div key={index}>{item}</div>;
            })}
          </div>
        </div>
      ) : (
        ""
      )}

      <Row style={{ height: "100%" }}>
        <Col lg="12">
          <Card className={border ? "bordered" : ""} style={{ height: "100%" }}>
            <Card.Body className="pt-0 rounded">
              <div className="custom-table-effect table-responsive">
                <table
                  className="table mb-0"
                  id="datatable"
                  data-toggle="data-table"
                >
                  {noHead ? (
                    ""
                  ) : (
                    <thead>
                      <tr className="bg-white border-bottom-blue">
                        {selectAll === true && firstCheckColumn === true ? (
                          <th
                            className={`py-5 px-0 `}
                            key={Math.random().toString()}
                          >
                            <div className="d-flex align-items-center justify-content-center m-1">
                              <input
                                className="form-check-input my-auto border border-primary"
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                onChange={handleSelectAll}
                                checked={isAllChecked}
                                disabled={checkboxDisabled}
                              />
                            </div>
                          </th>
                        ) : firstCheckColumn === true ? (
                          <tr className="p-0"></tr>
                        ) : (
                          ""
                        )}

                        {columns.map(({ label, sortable,showNumberInput }, index) => {
                          const cl = sortable
                            ? sortField === label && order === "asc"
                              ? "up"
                              : sortField === label && order === "desc"
                              ? "down"
                              : "default"
                            : "";
                            
                              return (
                                <th
                                  scope="col"
                                  className={`py-5 ${index + 1} ${
                                    positionOfCenteredColumns?.includes(index + 1)
                                      ? "text-center"
                                      : ""
                                  } ${
                                    positionOfEndColumns?.includes(index + 1)
                                      ? "text-end"
                                      : ""
                                  }`}
                                  key={`sortable-${index}`}
                                  onClick={
                                    sortable
                                      ? () => handleSortingChange(label)
                                      : null
                                  }
                                >
                                  {tableData[0] !== undefined &&
                                      Object.keys(tableData[0])?.map((key, indexElement) => {
                                        if(key!="id"){
                                          if (typeof tableData[0][key] === 'string' && indexElement - 1 == index || tableData[0][key]?.props?.image && indexElement - 1 == index) {
                                              return <div key={`column-header-${indexElement}`}
                                              style={{
                                                ...(sortable ? { flex: 1, display: 'flex', alignItems: 'center' } : {}),
                                              }}
                                              >{label} {sortable ? (
                                                <img
                                                  style={{
                                                    width: "12px",
                                                    marginLeft: "10px",
                                                    cursor: "pointer",
                                                  }}
                                                  key={`sort-img-${label}`}
                                                  src={sortIcon}
                                                  alt="Sort Icon"
                                                  className={cl}
                                                />
                                              ) : (
                                                ""
                                              )}</div>;
                                            }
                                          else if (indexElement - 1 == index){
                                            return <div key={`column-header-${indexElement}`} className="text-center"
                                            style={{
                                              ...(sortable ? { flex: 1, display: 'flex', alignItems: 'center' } : {}),
                                            }}
                                            >{label} {sortable ? (
                                              <img
                                                style={{
                                                  width: "12px",
                                                  marginLeft: "10px",
                                                  cursor: "pointer",
                                                }}
                                                key={`sort-img-${label}`}
                                                src={sortIcon}
                                                alt="Sort Icon"
                                                className={cl}
                                              />
                                            ) : (
                                              ""
                                            )}</div>;
                                          }
                                        }
                                    })}
                                  
                                  {showNumberInput && 
                                  <NumberInput
                                  style={{paddingTop:2}}
                                  withNumber={true}
                                  setCantidadVisitasTodo={setIncDecNumberTodo} 
                                  cantidadVisitasForAll={cantidadVisitasForAll}
                                />}
                                </th>
                              );
                          
                        })}
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    { isAllCheckedFromAllPages ? (
                      <tr className="alert-container">
                        <div className="">
                          <div
                            className="alert alert-success d-flex align-items-center w-100 border-0 justify-content-center"
                            role="alert"
                          >
                            <div className="ms-3 text-dark">
                              Se han seleccionado los <b>{totalQty} </b> de todas las páginas.
                            </div>
                          </div>
                        </div>
                      </tr>
                    ):isAllChecked && isSelectAllPages ? (
                      <tr className="alert-container">
                        <div className="">
                          <div
                            className="alert alert-success d-flex align-items-center w-100 border-0 justify-content-center"
                            role="alert"
                          >
                            <div className="ms-3 text-dark">
                              Se han seleccionado los {perPage}  de esta página.{" "}
                              <span
                                className="text-primary fw-bold"
                                style={{ textDecoration: "underline" }}
                              >
                                <Link
                                      to="#"
                                      onClick={() =>{
                                        props.selectAllFromAllPages && props.selectAllFromAllPages(!isAllCheckedFromAllPages)
                                        setIsAllCheckedFromAllPages(prevValue=>!prevValue)
                                      }
                                      }
                                    >Seleccionar los {totalQty}</Link> de la búsqueda.
                              </span>
                            </div>
                          </div>
                        </div>
                      </tr>
                    ) : (
                      ""
                    )
                    }
                    {tableData?.length > 0 && !props.isLoading ? (
                      tableData?.map((item, index) => {
                        return (
                          <tr key={index} className={index}>
                            {firstCheckColumn ? (
                              <Fragment>
                                <td className="p-0" key={`checkBoxTd-${item.id}`}>
                                  <div className="d-flex align-items-center justify-content-center" key={`tooltip-div-${item.id}`}>
                                    {showToolTip
                                    ? <TextToolTip child={rowCheckBox(index,item)} key={`tooltip-${item.id}`} toolTip={props.toolTip}/>
                                    :rowCheckBox(index,item)}
                                  </div>
                                </td>
                              </Fragment>
                            ) : (
                              ""
                            )}
                            {invalidIds && 
                              <td key={`itemvalid-${item.id}`}>
                                <div className="d-flex align-items-center">
                                  <div style={{display: "flex"}}>
                                  {invalidIds.includes(item.id)
                                    ? 
                                      <GrStatusGoodSmall
                                        color={colorsObjects["ORANGE"]}
                                        style={{width: "18px", height: "20px"}}
                                        key={`invalidstatus-${item.id}`}
                                      />
                                    :<GrStatusGoodSmall
                                        color={colorsObjects["LIGHT_GREEN"]}
                                        style={{width: "18px", height: "20px"}}
                                        key={`invalidstatus-${item.id}`}
                                      />}
                                  </div>
                                </div>
                              </td>}
                            <td key={`item-${item.id}`}>
                              <div className="d-flex align-items-center">
                                <div className="media-support-info d-flex justify-content-center align-items-center">
                                  <a
                                    onClick={()=>onClickFirstCol ? onClickFirstCol(Object.values(item)[0]) : null}
                                    style={{
                                      cursor:"pointer",
                                      textDecoration: underlineFirstColumn
                                        ? "underline"
                                        : "none",
                                      color: firstColumnColor || "#333333",
                                    }}
                                    className="mb-0"
                                  >
                                    {Object.values(item)[1]}
                                  </a>
                                </div>
                              </div>
                            </td>
                            {Object.keys(item).map((key,index) => {
                              if(key!="sortNumber"){
                                  return (
                                    index > 1 && (
                                      <td
                                        className={`text-dark ${index} ${
                                          positionOfCenteredColumns?.includes(index)
                                            ? "text-center"
                                            : ""
                                        } ${
                                          positionOfEndColumns?.includes(index)
                                            ? "text-end"
                                            : ""
                                        }`}
                                        style={
                                          esNumero(tableData[0][key])
                                          // esNumero(key)
                                           ? { textAlign: "center" } : {}}
                                      >
                                        {item[key]}
                                      </td>
                                      )
                                  );  
                                }
                            })}
                            {showNumberInput && (
                              <td key={`numberInput-${item.id}`}>
                                <NumberInput
                                  withNumber={true}
                                  setCantidadVisitas={setIncDecNumber}
                                  cantidadVisitasForAll={cantidadVisitasForAll}
                                  puntoId={item.id}
                                />
                              </td>
                            )}
                            {showDeleteLink && (
                              <td key={`deleteCol-${item.id}`}>
                                <Button
                                  className="btn bg-transparent border-0 btn-icon btn-sm rounded-pill"
                                  to="#"
                                  role="button"
                                  onClick={() => removeFunction(item.id)}
                                >
                                  <img
                                    width="16px"
                                    src={Remove}
                                    alt="Remove Button"
                                  />
                                </Button>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      props.isLoading ? 
                      <Spinner animation="border" variant="primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                      :
                      <tr className="d-flex align-items-center justify-content-between mx-auto">
                        <td>No hay datos disponibles</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Card.Body>
            {pagination ? (
              <Card.Footer className="d-flex justify-content-between mx-2">
                <Col md={6} className="d-flex align-items-center">
                  <p>
                    Mostrando {perPage} de {totalQty}
                  </p>
                </Col>
                <Col md={6}>
                  <div className="d-flex justify-content-end">
                    <nav>
                      <ul className="pagination">
                        {paginationLinks
                          ? paginationLinks.map((link, key) => {
                              if (
                                key !== 0 &&
                                key !== paginationLinks.length - 1
                              ) {
                                return (
                                  <li className="page-item" key={`pagination-${key}`}>
                                    <Link
                                      className={
                                        link.active
                                          ? "page-link active"
                                          : "page-link"
                                      }
                                      to=""
                                      key={link.label}
                                      onClick={() =>
                                        paginateFunction(link.label)
                                      }
                                    >
                                      {link.label}
                                    </Link>
                                  </li>
                                );
                              } else if (key === 0) {
                                return (
                                  <li className="page-item">
                                    <Link
                                      className="page-link"
                                      to="#"
                                      aria-label="Previous"
                                      key="previous"
                                      onClick={() =>
                                        prevLink &&  paginateFunction(prevLink)
                                      }
                                    >
                                      <span aria-hidden="true">
                                        <img
                                          style={{ width: "9px" }}
                                          src={Previous}
                                          alt="Previous img"
                                        />
                                      </span>
                                    </Link>
                                  </li>
                                );
                              } else {
                                return (
                                  <li className="page-item">
                                    <Link
                                      className="page-link"
                                      to="#"
                                      aria-label="Next"
                                      key="next"
                                      onClick={() =>
                                        nextLink && paginateFunction(nextLink)
                                      }
                                    >
                                      <span aria-hidden="true">
                                        <img
                                          style={{ width: "9px" }}
                                          src={Next}
                                          alt="Next img"
                                        />
                                      </span>
                                    </Link>
                                  </li>
                                );
                              }
                            })
                          : null}
                      </ul>
                    </nav>
                  </div>
                </Col>
              </Card.Footer>
            ) : (
              ""
            )}
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

Table.displayName = "Table";
export default Table;
