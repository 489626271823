import { Droppable, Draggable } from "react-beautiful-dnd";

import DragDropImg from "../../assets/images/icons/DragDropImg.svg";
import DeleteItemImg from "../../assets/images/icons/DeleteItemImg.svg";
import { Fragment } from "react";

const DragDropComponent = (props) => {
  const {paradas,deleteFunction} = props
  return (
    <div style={{ width: "100%" }}>
      <Droppable droppableId="DC_drop_area">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <div
              style={{
                listStyleType: "none",
                textAlign: "left",
                padding: "0%",
                width: "100%",
              }}
            >
              <h6
                className="px-3 my-4 fw-bold"
                style={{ color: "#000061", fontSize: "16px" }}
              >
                Paradas
              </h6>
              {paradas?.map((data, index) => (
                <Draggable
                  key={`${data.id}`}
                  draggableId={`${data.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Fragment>
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={`container-${data.id}`}
                        className="d-flex align-items-center pe-4"
                      >
                        <div
                          key={`divParent-${data.id}`}
                          style={{
                            userSelect: "none",
                            background: snapshot.isDragging
                              ? "#E6F4FF"
                              : "white",
                            padding: "2%",
                            paddingLeft: "2%",
                            border: "1.5px solid blue",
                            borderRadius: "7.5px",
                            fontSize: "16px",
                            width: "90%",
                            opacity: snapshot.isDragging ? "0.5" : "1",
                          }}
                          className="m-3 text-dark d-flex justify-content-between align-items-center px-3"
                        >
                          <div className="d-flex flex-column">
                            <div style={{ fontSize: "14px" }}>
                              {data.nombre}
                            </div>
                            <div
                              style={{
                                fontSize: "12px",
                                color: "blue",
                                textDecoration: "underline",
                              }}
                            >
                              {data.direccion}
                            </div>
                          </div>
                          <div className="mx-2 gap-2 d-flex">
                            <img
                              src={DragDropImg}
                              {...provided.dragHandleProps}
                              alt="Drag Drop Img"
                            />
                          </div>
                        </div>
                        <img
                          src={DeleteItemImg}
                          onClick={()=>deleteFunction(data.id)}
                          className="mr-3"
                          alt="Delete item Img"
                          key={`delete-${data.id}`}
                        />
                      </div>
                    </Fragment>
                  )}
                </Draggable>
              ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default DragDropComponent;
