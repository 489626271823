import { memo, Fragment, useEffect, useState } from "react";

//React-bootstrap
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
//Img
import profile from "../../../../assets/images/avatars/default.png";
import  secureLocalStorage  from  "react-secure-storage";
import { getCorporacion } from "../../../../dataservice/AuthDataService"
const Sidebarprofilecard = () => {
  const merchandiser = secureLocalStorage.getItem('user');
  const [corporacion, setCorporacion] = useState([]);
  useEffect(()=>{
    leerCorporacion()
  },[])
  const leerCorporacion = async() =>{
    const response = await getCorporacion();
    setCorporacion(response.data.data)
  }
  return (
    <Fragment>
      <div className="sidebar-profile-card mt-3">
        <div className="sidebar-profile-body">
          <div className="mb-3 d-flex justify-content-center">
            <div className="rounded-3 border-primary p-2">
              <Image src={corporacion.foto_path} alt="1" className="img-fluid rounded" />
            </div>
          </div>
          <div className="sidebar-profile-detail">
            <p className="sidebar-profile-name">{corporacion.nombre}.</p>
            <span className="sidebar-profile-username fw-bold">{merchandiser.info.user.name}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Sidebarprofilecard.displayName = "Sidebarprofilecard";
export default Sidebarprofilecard;
