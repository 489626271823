import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export const TextToolTip = ({ child, toolTip }) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip id="text-tooltip">{toolTip}</Tooltip>}
      placement="auto"
    >
      <div style={{display: 'inline-block', cursor: 'not-allowed'}}>{child}</div>
    </OverlayTrigger>
  );
}