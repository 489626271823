const initialState = {
    merchandisers:[],
    puntos:[],
    promoterId:0,
    cadenaId:0
};
export default (state = initialState, action) => {
    switch(action.type){
        case 'PROMOTER_MERCHANS':
                return {
                    ...state,
                    merchandisers:action.payload
                }
        case 'PROMOTER_CADENAS':
                return {
                    ...state,
                    puntos:action.payload
                }
        case 'PROMOTER_ID':
                return{
                    ...state,
                    promoterId:action.payload
                }
        case 'SELECTED_CADENA':
                    return{
                        ...state,
                        cadenaId:action.payload
                    }
        default:
            return state;
    }
}