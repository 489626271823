import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
let weeks = [];
export const getWeeksDaysGrid = () => {
    let grid = []
    for(let week = 1; week <= 6; week ++ ){
        let dias = []
        for(let day = 1; day<=7; day++){
            dias.push({dia:day,puntos:[]})
        }
        grid.push({semana:week,dias:dias})
    }
    return grid;
}
export const days = [
    {title:"Lunes",
    number:1,
    disabled:false},
    {title:"Martes",
    number:2,
    disabled:false},
    {title:"Miércoles",
    number:3,
    disabled:false},
    {title:"Jueves",
    number:4,
    disabled:false},
    {title:"Viernes",
    number:5,
    disabled:false},
    {title:"Sábado",
    number:6,
    disabled:false}
]
export const getWeeksGrid = () => {
    const mes = moment().format('MM')
    const startDate = moment().month(mes-1).startOf('month');
    let firstDay = moment()
    let endDay = moment(startDate).endOf('month')
    let monthRange = moment.range(firstDay, endDay)
    let numWeeks = Math.ceil(firstDay.date() / 7);
    let weekEnd
    for (let week of monthRange.by('weeks')) {
        let weekStart = week.clone().startOf('week')
        weekEnd = week.clone().endOf('week')
        let weekRange = moment.range(weekStart, weekEnd)
        weeks[numWeeks] = []
        let datesOfWeek = getDatesOfWeek(weekRange,startDate,endDay);
        weeks[numWeeks] = datesOfWeek
        ++numWeeks
    }
    if(endDay.diff(weekEnd,'days')>0){
        let restRange = moment.range(weekEnd.add(1,'d'), endDay)
        weeks[++numWeeks] = getDatesOfWeek(restRange,startDate,endDay)
        weeks[numWeeks].range = weekEnd.format("DD-MM") + " a " + endDay.format("DD-MM")
    }
    return weeks;
}
const getDatesOfWeek = (weekRange,startDate,endDay) => {
    let dates = []
    for(let day of weekRange.by('days')){
        if(day.isSameOrAfter(startDate) && day.isSameOrBefore(endDay)){
            let datePuntos = {dia:day.day()}
            datePuntos.disabled = true;
            if(day.isAfter(moment().startOf('day'))){
                datePuntos.disabled = false;
            }
            dates.push(datePuntos)
        }
    }
    return dates
}
export const activeSemanas = () => {
    let semanasActive = Object.keys(weeks).filter(
        ((weekNum)=>weeks[weekNum].filter(dia=>dia.disabled==false).length>0));
    return semanasActive?.map(semana=>+semana)

}