import { useEffect, memo, Fragment } from "react";
import { useSelector } from "react-redux";

//Sidebarprofile
import Sidebarprofilecard from "../../../../../components/partials/components/sidebar/sidebar-profile-card";
//E-commerce-vartical-nav
import Ecommercevarticalnav from "./e-commerce-vartical-nav";
//Scrollbar
import Scrollbar from "smooth-scrollbar";
// Import selectors & action from setting store
import * as SettingSelector from "../../../../../store/setting/selectors";
import styles from "./eCommers.module.css";
import alisterIcon from "../../../../../../src/assets/images/auth/mambo-icon-1.png";
import alisterIconText from "../../../../../../src/assets/images/auth/mamboLogo.png";

const Ecommercesidebar = memo(() => {
  const sidebarColor = useSelector(SettingSelector.sidebar_color);
  const sidebarHide = useSelector(SettingSelector.sidebar_show); // array
  const sidebarType = useSelector(SettingSelector.sidebar_type); // array
  const sidebarMenuStyle = useSelector(SettingSelector.sidebar_menu_style);

  return (
    <Fragment>
      <aside
        className={`${sidebarColor} ${sidebarType.join(
          " "
        )} ${sidebarMenuStyle} ${
          sidebarHide.join(" ") ? "sidebar-none" : "sidebar"
        } sidebar-base `}
      >
        <div
          style={{ overflow: "scroll", scrollbarWidth: "none" }}
          className={`${styles.hideScroll} sidebar-body pt-0 data-scrollbar`}
          id="my-scrollbar"
        >
          <Sidebarprofilecard />
          <hr className="hr-horizontal" />
          <div className="sidebar-list">
            <Ecommercevarticalnav />
          </div>
        </div>
        <div
          style={{
            position: "relative",
            marginLeft: "12px",
            textAlign: "center",
          }}
        >
        <p
            style={{
              color: "#B7B7B7",
              position: "absolute",
              bottom: 58,
              left: 18,
              right: 0,
              fontSize: "12 px",
            }}
          >
            Versión 1.1
          </p>
          <img
            style={{ marginTop: "40px", width: "140px" }}
            src={alisterIcon}
            alt="Alister-logo-1"
          />
          <img
            style={{ width: "140px", marginTop: "16px", marginBottom: "5px" }}
            src={alisterIconText}
            alt="Alister-logo-2"
          />
        </div>
      </aside>
    </Fragment>
  );
});

Ecommercesidebar.displayName = "Ecommercesidebar";
export default Ecommercesidebar;
