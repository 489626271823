import {loginCall} from 'dataservice/AuthDataService'
const initialState = {
    user:{},
    merchandiser:{},
    puntos:{},
    merchanSelected:{},
    is_itinerario_edit:false,
    loading:false
};
export default (state = initialState, action) => {
    switch(action.type){
        case 'LOGIN':
                return {
                    ...state,
                    user:action.payload
                }
        case 'SETMERCHANDISER':
                return {
                    ...state,
                    merchandiser:action.payload
                }
        case 'UNSETMERCHANDISER':
                return {
                    ...state,
                    merchandiser:{}
                }
        case 'MES':
            return {
                ...state,
                mes:action.payload
            }
        case 'ITINERARIO':
            return {
                ...state,
                itinerario:action.payload
            }
        case 'IS_ITINERARIO_EDIT':
                return {
                    ...state,
                    is_itinerario_edit:action.payload
                }
        case 'PUNTOS':
            return {
                ...state,
                puntos:action.payload
            }
        case 'MERCHANSELECTED':
            return {
                ...state,
                merchanSelected:action.payload
            }
        case 'SHOW_LOADER':
            return {
                ...state,
                loading:true
            }
        case 'HIDE_LOADER':
            return {
                ...state,
                loading:false
            }
        default:
            return state;
    }
}
export function login(data){
    return async function loginUser(dispatch, getState) {
        const response = await loginCall(data)  
        const user = response.data  
        dispatch({ type: 'LOGIN', payload:user});
        return user;
      }
}