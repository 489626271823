import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import "./NumberInput.css";

const NumberInput = (props) => {
  const [number, setNumber] = useState(null);
  useEffect(()=>{
    setNumber(props.cantidadVisitasForAll)
  },[props.cantidadVisitasForAll])
  const withNumber = props.withNumber;
  

  
  const validateForm = (value) => {
    if(value.trim()=="") return true;
    let isNum = /^\d+$/.test(value);
    if(!isNum)return false
    let numberValue = Number(value);
    return numberValue<=2147483647
  }
  const handleInputChange = (e) => {
    if(!validateForm(e.target.value)){
      return
    }
    setNumber(e.target.value);
    props.setCantidadVisitas && props.setCantidadVisitas(e.target.value, props.puntoId);
    props.setCantidadVisitasTodo && props.setCantidadVisitasTodo(e.target.value);
  };

  return (
    <InputGroup className="d-flex justify-content-center">
      {withNumber && (
        <Form.Control
          className="number-input"
          value={number ?? ""}
          onChange={handleInputChange}
        />
      )}
    </InputGroup>
  );
};

export default NumberInput;
