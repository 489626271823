export const colorsObjects = {
  BG_PRIMARY: "#E6F4FF",
  BLACK: "#000000",
  BLUE: "#00008F",
  GREEN: "#007657",
  DARK_BLUE: "#000061",
  DARK_GREEN: "#007657",
  LIGHT_BLUE: "#9DBCF9",
  LIGHT_BLUE_MEDIUM: "#D5E7FE",
  LIGHT_GRAY: "#C7CDD5",
  LIGHT_GRAY_MEDIUM: "#6F6F6F",
  LIGHT_GREEN: "#05B182",
  MEDIUM_BLUE: "#000ECE",
  MEDIUM_GRAY: "#4D4D4D",
  WHITE: "#FFFFFF",
  ORANGE: "#FF5A22",
  LIGHT_ORANGE: "#FFECE6",
  YELLOW: "#FFD500",
  YELLOW_MEDIUM: "#FFC431",
};
