import apiClient from "./AxiosRequest";

let cancelToken;
export const getItinerarioList =  (page=null,filters) => {
    if(page){
        return apiClient().post('/show_itinerario_filtrado?page='+page,filters)
    }
    return apiClient().post('/show_itinerario_filtrado',filters)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('show_itinerario error: ', error.response.data);
            console.log('show_itinerario status', error.response.status);
            console.log('show_itinerario headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("show_itinerario error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('show_itinerario error', error.message);
        }
    });
}
export const getGrupos =  () => {
    return apiClient().get('/get_grupos')
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}
export const getMerchandiser =  (grupo_id,filters) => {
    return apiClient().post('/show_merchandisers/' + grupo_id,filters)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}
export const getPuntoMerchandiser =  (merchandiser, mes, cantidad,filters) => {
    let fields = {
        fecha: parseInt(mes),
        cantidad: cantidad
    }
    fields = {...fields,...filters}
    return apiClient().post('/show_punto_merchan/'+ merchandiser, fields)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const searchPuntoMerchan =  (merchandiser, search) => {
    let request = apiClient();
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.")
    }
    cancelToken = request.CancelToken
    return apiClient().get('/search_punto_merchan/'+ merchandiser + "/" + search, {cancelToken:cancelToken.token})
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const sentItinerario =  (fechas, orden, puntos, merchandiser) => {
    let fields = {
        fechas: fechas,
        orden: orden,
        seleccionados: puntos,
        merchandiser_id: merchandiser
    }
    return apiClient().post('/crear_itinerario', fields)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const deleteItinerarioCall =  (itinerarioId) => {
    return apiClient().delete('/itinerarios/' + itinerarioId)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}
export const getPuntosItinerarios =  (itinerarioId) => {
    return apiClient().post('/show_itinerario_punto_seleccionado/' + itinerarioId)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('puntos error: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("puntos error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('puntos error', error.message);
        }
    });
}
export const editItinerario =  (fecha, orden, puntos, merchandiser) => {
    let fields = {
        fecha: fecha,
        orden: orden,
        seleccionados: puntos,
        merchandiser_id: merchandiser
    }
    return apiClient().post('/edit_itinerario', fields)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
//eliminar_itinerario_punto
export const eliminarItinerario =  (fecha, puntos, merchandiser) => {
    let fields = {
        fecha: fecha,
        sobrantes: puntos,
        merchandiser_id: merchandiser
    }

    return apiClient().post('/eliminar_itinerario_punto', fields)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}

export const monthlyItinerario =  (requestObj,merchandiserId) => {
    let fields = {
        monthlyItinerario: requestObj,
        merchandiserId:merchandiserId
    }

    return apiClient().post('/monthly_itinerario', fields)

    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const updateMonthlyItinerario =  (requestObj,merchandiserId) => {
    let fields = {
        monthlyItinerario: requestObj,
        merchandiserId:merchandiserId
    }

    return apiClient().post('/monthly_itinerario_update', fields)

    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const getLastMonthItinerary =  (month,merchandiserId) => {
    return apiClient().get('/last_month_itinerario/'+ month + "/" + merchandiserId)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const getPlanItinerario = (merchandiserId)=>{
    return apiClient().get('/plan_itinerario/'+ merchandiserId)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}

