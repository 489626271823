
import axios from 'axios';
import  secureLocalStorage  from  "react-secure-storage";

const apiClient = () => {
  const hostname = window.location.hostname;
  let baseUrl ="";
  switch (hostname) {
    case "localhost":
      baseUrl='http://localhost/api/';
      break;
    case "stagingweb.alister.app":
      baseUrl='https://stagingapi.alister.app/api/';
      break;
    case "web.alister.app":
      baseUrl='https://api.alister.app/api/';
      break;
    case "betaweb.alister.app":
      baseUrl='https://betaapi.alister.app/api/';
      break;
    default:
      baseUrl='https://betaapi.alister.app/api/';
      break;
  }

  const user=secureLocalStorage.getItem("user");
  const access_token = user?.info?.token;
  //console.log("baseUrl: ", baseUrl);
  const instance = axios.create({
    //baseURL: process.env.REACT_APP_API_URL,
    // baseURL: process.env.REACT_APP_API_URL,
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${access_token}`,
      'User-Agent': "web: "+ (user ? JSON.stringify(user) : 'user no logueado'),
    },
    responseType: 'json',
  });
  instance.CancelToken = axios.CancelToken.source();
  instance.isCancel = axios.isCancel;
  return instance;
};

export default apiClient;
