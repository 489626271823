import { memo, useEffect, useState,Fragment, useCallback } from "react";
import { Button } from "react-bootstrap";
import Table from "components/Table/index";
import moment from 'moment';
import Check2 from "../../../src/assets/images/icons/Check2.svg";

import Checked from "assets/images/icons/Checked.svg";
import Close from "assets/images/icons/Close.svg";
import SearchInput from "components/SearchInput/SearchInput";
import TabPane from "components/TabPane/index";
import { useQuery, useQueryClient } from "react-query"
import { getHistorialPedidosCall, getPedidosPendientesCall, searchHistorial, searchPendientes, updatePedido, updatePedidos } from "dataservice/PedidoTransferDataService";
import PedidoDetailModal from "./PedidoDetailModal";
import { ALERT_DURATION } from "constants/System/index";
import { useDispatch } from "react-redux";
const columns = [
    { label: "N°", sortable: true},
    { label: "PDV", sortable: true },
    { label: "Merchan", sortable: true},
    { label: "Estado", sortable: true },
    { label: "Fecha pedido", sortable: true},
    { label: "Acciones" },
  ];
  const historialColumns = [
    { label: "N°", sortable: true},
    { label: "PDV", sortable: true },
    { label: "User", sortable: true},
    { label: "Estado", sortable: true },
    { label: "Fecha respuesta", sortable: true }]
  const items = [
    { label: Checked, alt: "Checked one" },
    { label: Close, alt: "Close one" },
  ];
  const outItems = [
    { label: Checked, alt: "Checked one" },
    { label: Close, alt: "Close one" },
  ];
const PedidoTransfer = memo((props) => {
    const [idsSelected, setIdsSelected] = useState([]);
    const [selectedTabId, setSelectedTabId] = useState(0);
    const [page, setPage] = useState(1);  
    const [pageSearchPendientes, setPageSearchPendientes] = useState(1);    
    const [pageSearchHistorial, setPageSearchHistorial] = useState(1);    
    const [pageHistorial, setPageHistorial] = useState(1);

    const [paginationLinks, setPaginationLinks] = useState([]);
    const [totalPuntos, setTotalPuntos] = useState([]);
    const [perPage, setPerPage] = useState([]);
    const [showPedidoProducto, setShowPedidoProducto] = useState(false);
    const[pedidoSelected,setPedidoSelected] = useState(null)
    const [statusMessage,setStatusMessage] = useState("");
    const [showStatus,setShowStatus] = useState(true)
    const [historial,setHistorial] = useState([])
    const [paginationLinksHistorial, setPaginationLinksHistorial] = useState([]);
    const [totalPuntosHistorial, setTotalPuntosHistorial] = useState([]);
    const [perPageHistorial, setPerPageHistorial] = useState([]);
    const dispatch = useDispatch()
    const[productosMode,setProductosMode] = useState("SHOW")
    const [searchQuery, setSearchQuery] = useState(null);
    const [pedidos, setPedidos] = useState([]);
    const actionButtons = (items) => {
        return items.map((item) => (
          <Button
            className="btn bg-transparent border-0 btn-icon btn-sm rounded-pill"
            to={""}
            role="button"
            onClick={() =>
              item.alt === "Close one" ?  rechazarMultiple() : aprobarMultiple()
            }
          >
            <img
              width={item.label == Checked ? "22px" : "16px"}
              src={item.label}
              alt={item.alt}
            />
          </Button>
        ));
      };
    const outActionButtons = [actionButtons(outItems)];
    const { pageName } = props;
    const queryClient = useQueryClient();
    const {
        data: dataPedidosPendientes,
        isLoading: isLoadingPedidoPendientes,
        isError: isErrorPedidoPendientes,
        error: errorPedidoPendientes,
        isFetching: isFetchingPedidoPendientes,
      } = useQuery({
        queryKey: ['pedido_transfer', page],
        queryFn: () => getPedidosPendientesCall(page),
        staleTime: 1000 * 60 * 60*24, // 1 minuto
      });
      const {
        data: dataHistorial,
        isLoading: isLoadingHistorial,
        isError: isErrorHistorial,
        error: errorHistorial,
        isFetching: isFetchingHistorial,
      } = useQuery({
        queryKey: ['historial_transfer', page],
        queryFn: () => getHistorialPedidosCall(page),
        staleTime: 1000 * 60 * 60 * 24, // 1 minuto
        enabled: selectedTabId == 1
      });
      const {
        data: dataSearchPendientes,
        isLoading: isLoadingSearchPendientes,
        isError: isErrorSearchPendientes,
        error: errorSearchPendientes,
      } = useQuery({
        queryKey: ['search_pendientes', pageSearchPendientes,searchQuery],
        queryFn: () => searchPendientes(searchQuery,pageSearchPendientes),
        staleTime: 1000 * 60 * 5, // 1 minuto
        enabled: (searchQuery!=null && searchQuery!="") && selectedTabId == 0
      });
      const {
        data: dataSearchHistorial,
        isLoading: isLoadingSearchHistorial,
        isError: isErrorSearchHistorial,
        error: errorSearchHistorial,
      } = useQuery({
        queryKey: ['search_historial', pageSearchHistorial,searchQuery],
        queryFn: () => searchHistorial(searchQuery,pageSearchHistorial),
        staleTime: 1000 * 60 * 5, // 1 minuto
        enabled: (searchQuery!=null && searchQuery!="") && selectedTabId == 1
      });
      useEffect(() => {
        if (dataPedidosPendientes) {
          formatPedidos(dataPedidosPendientes.data)
        }
      }, [dataPedidosPendientes]);
    useEffect(() => {
        if (dataSearchPendientes) {
            formatPedidos(dataSearchPendientes.data)
        }
    }, [dataSearchPendientes]);
      useEffect(()=>{
        if(dataHistorial){
            formatHistorial(dataHistorial.data)
        }
      },[dataHistorial])
      useEffect(() => {
        if (dataSearchHistorial?.data) {
            formatHistorial(dataSearchHistorial.data)
        }
    }, [dataSearchHistorial]);
      const formatPedidos = (pedidos) => {

        let pedidosFormatted =  pedidos?.data.map((pedido) => {
            let fecha = moment(pedido.created_at,'YYYY-MM-DD HH:mm:ss').format("DD-MM-YYYY HH:mm:ss")
          return {
            id: pedido.id,
            serial_no: <a onClick={()=>handleShowProductos(pedido,true)}>{pedido.serial_no}</a>,
            PDV:pedido.itinerario_punto.punto_merchandiser.punto.nombre,
            Merchan: pedido.itinerario_punto.punto_merchandiser.merchandiser.nombre,
            Estado: pedido.pedido_transfer_estado[0].estado,
            "Fecha pedido": fecha,
            Acciones: actionRowButtons(items, pedido.id),
          };
        });
        setPedidos(pedidosFormatted);
        setPaginationLinks(pedidos?.links);
        setTotalPuntos(pedidos?.total);
        setPerPage(pedidos?.from + " al " + pedidos?.to);
      }
      const formatHistorial = (pedidos) => {
        let pedidosFormatted =  pedidos?.data.map((pedido) => {
            let estadoNoPendientes = pedido.pedido_transfer_estado.find((estado)=>estado.estado!="PENDIENTE")
            let fecha = moment(estadoNoPendientes.created_at,'YYYY-MM-DD HH:mm:ss').format("DD-MM-YYYY HH:mm:ss")
            return {
                id: pedido.id,
                serial_no: <a onClick={()=>handleShowProductos(pedido,false)}>{pedido.serial_no}</a>,
                PDV:pedido.itinerario_punto.punto_merchandiser.punto.nombre,
                User: estadoNoPendientes.user.name,
                Estado: estadoNoPendientes.estado,
                "Fecha respuesta": fecha,
                };
        });
        setHistorial(pedidosFormatted);
        setPaginationLinksHistorial(pedidos?.links);
        setTotalPuntosHistorial(pedidos?.total);
        setPerPageHistorial(pedidos?.from + " al " + pedidos.to);
      }
      const accion = (item, pedido) => {
        switch (item.alt) {
          case "Checked one":
            aceptarPedido(pedido);
            setStatusMessage({message:"Se acepto la solicitud",
              type:"sucess"})
            setShowStatus(true)
            break;
          case "Close one":
            rechazarPedido(pedido)
            setStatusMessage({message:"Se rechazo la solicitud",
              type:"sucess"})
            setShowStatus(true)
            break;
        }
      };
      const aceptarPedido = async(pedidoId) => {
        const response = await updatePedido("APROBADO",pedidoId)
        if(response?.status===200){
            setStatusMessage({message:"Se acepto la solicitud",
              type:"sucess"})
            setShowStatus(true)
        }
        else{
            setStatusMessage({message:"Ocurrió un error al aprobar el pedido",
              type:"danger"})
            setShowStatus(true)
        }
    }
    const rechazarPedido = async(pedidoId) => {
        const response = await updatePedido("RECHAZADO",pedidoId)
        if(response?.status===200){
            setStatusMessage({message:"El pedido fue rechazado exitosamente",
              type:"sucess"})
            setShowStatus(true)
        }
        else{
            setStatusMessage({message:"Ocurrió un error al rechazar el pedido",
              type:"danger"})
            setShowStatus(true)
        }
    }
      const actionRowButtons = (items, pedido) => {
        return items.map((item) => (
          <Button
            className="btn bg-transparent border-0 btn-icon btn-sm rounded-pill"
            to={pedido}
            role="button"
            onClick={() => accion(item, pedido)}
          >
            <img
              width={item.label == Checked ? "22px" : "16px"}
              src={item.label}
              alt={item.alt}
            />
          </Button>
        ));
      };
      
      const tabla = (
        <Table
          data={pedidos}
          columns={columns}
          selectAll={true}
          outActionButtons={outActionButtons}
          firstColumnColor={true}
          underlineFirstColumn={true}
          border={true}
          title={"Solicitudes"}
          link={null}
          setIds={setIdsSelected}
          pagination={true}
          paginationLinks={paginationLinks}
          paginateFunction={setPage}
          totalQty={totalPuntos}
          perPage={perPage}
        />
      );
      const tablaHistorial = (
        <Table
          data={historial}
          columns={historialColumns}
          firstCheckColumn={false}
          firstColumnColor={true}
          underlineFirstColumn={true}
          border={true}
          title={"Solicitudes"}
          link={null}
          pagination={true}
          paginationLinks={paginationLinksHistorial}
          paginateFunction={setPageHistorial}
          totalQty={totalPuntosHistorial}
          perPage={perPageHistorial}
        />
      );
      const tabs = useCallback(
        [
          {
            title: "PEDIDOS",
            content: tabla,
            key: 0,
          },
          {
            title: "HISTORIAL",
            content: tablaHistorial,
            key: 1,
          },
        ],
        [pedidos, idsSelected, historial]
      );
      const handleShowProductos= (pedido=null,isActionable) => {
        setPedidoSelected(pedido); 
        setShowPedidoProducto(true);
        if(isActionable){
            setProductosMode("EDIT")
        }
    }
    const handleCloseProductos= (statusMessage) => {
        setProductosMode("SHOW")
        setPedidoSelected(null); 
        setStatusMessage(statusMessage)
        setShowStatus(true)
        setShowPedidoProducto(false);
    }
    useEffect(()=>{
      if(statusMessage?.message!=null){
        dispatch({type:"SHOW_LOADER"})
      }
        setTimeout(function() {
          setShowStatus(false)
          setStatusMessage("")
          dispatch({type:"HIDE_LOADER"})
          queryClient.invalidateQueries('pedido_transfer')
          queryClient.invalidateQueries('historial_transfer')
        }, ALERT_DURATION);
    },[statusMessage])
    const rechazarMultiple = async() => {
        let recahazdoRequest = idsSelected.map((id) => {
          return ({pedido_transfer_id:id,status:"RECHAZADO"})
        });
        const response = await updatePedidos(recahazdoRequest)
        if(response?.status===200){
            setStatusMessage({message:"Se rechazo las solicitudes",
              type:"sucess"})
            setShowStatus(true)
        }
        else{
            setStatusMessage({message:"Ocurrió un error al rechazar los pedidos",
              type:"danger"})
            setShowStatus(true)
        }
      }
      const aprobarMultiple = async() => {
        let aprobadoRequest = idsSelected.map((id) => {
          return ({pedido_transfer_id:id,status:"APROBADO"})
        });
        const response = await updatePedidos(aprobadoRequest)
        if(response?.status===200){
            setStatusMessage({message:"Se acepto las solicitudes",
              type:"sucess"})
            setShowStatus(true)
        }
        else{
            setStatusMessage({message:"Ocurrió un error al aceptar los pedidos",
              type:"danger"})
            setShowStatus(true)
        }
      }
      useEffect(()=>{
        if(isLoadingPedidoPendientes || isLoadingHistorial 
            || isFetchingPedidoPendientes || isFetchingHistorial
            || isLoadingSearchPendientes || isLoadingSearchHistorial){
          dispatch({type:"SHOW_LOADER"})
          return
        }
        dispatch({type:"HIDE_LOADER"})
      },[isLoadingPedidoPendientes,isLoadingHistorial,
        isFetchingPedidoPendientes,isFetchingHistorial,
        isLoadingSearchPendientes,isLoadingSearchHistorial])
      const debounce = (func,delay=500) =>{
        let timeOut;
        return function (...args) {
            clearTimeout(timeOut);
            timeOut = setTimeout(() => {
                func(...args);
              }, delay);

        }
    }
    const search = (searchText) => {
        debounce(setSearchQuery(searchText),500)
    }
    useEffect(()=>{
        if(searchQuery==null || searchQuery==""){
            if(selectedTabId==0 && dataPedidosPendientes){
                formatPedidos(dataPedidosPendientes.data)
            }
            if(selectedTabId==1 && dataHistorial){
                formatHistorial(dataHistorial.data)
            }
        }
    },[searchQuery])
    return (
    <Fragment>
      <div className="d-flex flex-row align-items-end justify-content-between">
        <div className="d-flex flex-column">
          <h3 className="fw-bold m-0 p-2">{pageName}</h3>
        </div>
      </div>
      <div
        className="d-flex flex-row justify-content-between"
        style={{ flexDirection: "column" }}
      >
        <div className="w-100 pe-4">
          <SearchInput placeholder="Buscar..." searchFunction={search} />
        </div>
       
      </div>
      {statusMessage?.type == 'sucess' && showStatus === true && <div className="alert-container">
          <div
            className="alert alert-success d-flex align-items-center w-50 fw-bold p-4"
            role="alert"
          >
            <img src={Check2} alt="Check icon" />
            <div className="ms-3 text-dark">¡{statusMessage?.message}!</div>
          </div>
        </div>}
        {statusMessage?.type == 'danger' && showStatus === true && <div className="alert-container">
          <div
              className="alert alert-danger d-flex align-items-center w-50 fw-bold p-4"
              role="alert"
            >
            <img src={Check2} alt="Check icon" />
            <div className="ms-3 text-dark">{statusMessage?.message}</div>
          </div>
        </div>}
      <TabPane tabs={tabs} setSelectedTabId={setSelectedTabId} />
      <PedidoDetailModal
        pedido={pedidoSelected}
        handleClose={handleCloseProductos}
        show={showPedidoProducto}
        mode={productosMode}
      />
    </Fragment>)
})
PedidoTransfer.displayName = "Solicitudes Transfer";
export default PedidoTransfer;

