import apiClient from "dataservice/AxiosRequest";
import React, {useEffect, useState} from "react";
import lupaIcon from "../../assets/images/icons/lupa.svg";
import "./SearchInput.css";

const SearchInput = (props) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  let cancel = null;
  const handleOnInputChange = (event) => {
    setQuery(event.target.value);
    setLoading(true);
  };
  useEffect(() => {
    fetchSearchResults();
  }, [query]);
  const fetchSearchResults = async (updatedPageNo = "") => {
    if (apiClient().CancelToken) {
      apiClient().CancelToken.cancel();
    }
    props.searchFunction(query, apiClient().CancelToken);
  };
  return (
    <div className="form-group input-group form-group-alt mt-3 border-color border-radius"
    style={{position: "sticky",top: "0",zIndex:"100",background:"#f4f7fe"}}>
      <span className="input-group-text border-radius-left" id="basic-addon1">
        <img className="lupa-icon" src={lupaIcon} alt="Lupa Icon SVG" />
      </span>
      <input
        type="text"
        name="query"
        value={query}
        className="form-control border-radius-right py-3"
        placeholder={props.placeholder}
        aria-label="buscador"
        onChange={handleOnInputChange}
        aria-describedby="basic-addon1"
        style={props.style}
      />
    </div>
  );
};

export default SearchInput;
