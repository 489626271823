
import { connect } from "react-redux";
import {Spinner} from "react-bootstrap";
const PageLoader = (props) => {
    if(!props.loading) return null
    return (
        <div style={{position: "fixed",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex:"100",
              backgroundColor: "#F8F8F8AD"}}>
                <Spinner animation="border" variant="primary" role="status" >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                
              </div>
    )
}
// const mapStateToProps = state => ( return { loading: state.appDatos.loading });
const mapStateToProps = function(state) {
    return {
      loading: state.appDatos.loading,
    }
  }

export default connect(mapStateToProps)(PageLoader);