const initialState = {
    hallazgos:[],
    cadenas:[],
    hallazgosToDelete:[],
    categoriaId:''
};
export default (state = initialState, action) => {
    switch(action.type){
        case 'SET_HALLAZGOS':
                return {
                    ...state,
                    hallazgos:action.payload
                }
        case 'HALLAZGO_CADENAS':
                return {
                    ...state,
                    cadenas:action.payload
                }
        case 'HALLAZGO_TO_DELETE':
            return {
                ...state,
                hallazgosToDelete:action.payload
            }
        case 'CATEGORIA_ID':
            return {
                ...state,
                categoriaId:action.payload
            }
        default:
            return state;
    }
}