import BreadcrumbItem from "components/BreadcrumbItem/index";
import ColumnFooter from "components/ColumnFooter/ColumnFooter";
import Card from "components/bootstrap/card";
import DragDropComponent from "components/DragDropComponent/index";
import { useNavigate } from "react-router-dom";
import React, { Fragment, memo, useEffect, useState } from "react";
import { Row,Accordion,Spinner } from "react-bootstrap";
import { DragDropContext } from "react-beautiful-dnd";
import ItinerarioMap from "./ItinerarioMap";
import { getPuntosOrdenados } from "dataservice/PuntosDataService"
import { useSelector } from "react-redux";
import { monthlyItinerario, updateMonthlyItinerario } from "../../dataservice/ItinerarioDataService";
import { days } from "./SemanaGrid";
const ConfirmarMonthlyItinerario = memo((props) => {
  const { pageName } = props;
  const navigate = useNavigate();
  const mes = useSelector(store=>store.appDatos.mes)  
  const merchandiser = useSelector((store)=>{return store.appDatos.merchandiser})
  const [puntosDeSemana,setPuntosDeSemana] = useState([])
  const [selectedFecha,setSelectedFecha] = useState({semana:-1,dia:-1})
  const [mapPuntos,setMapPuntos] = useState([])
  const [totalPuntos,setTotalPuntos] = useState(0)
  const puntoFecha =  useSelector((store)=>{return store.appDatos.itinerario})
  const isEditItinerario =  useSelector((store)=>{return store.appDatos.is_itinerario_edit})
  const [loading,setLoading] =  useState(false)
  useEffect(()=>{
    setLoading(true)
    leerPuntos()
    if(Object.keys(merchandiser).length == 0){
      navigate('/itinerario/nuevo-itinerario')
    }
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  },[])
  const leerPuntos = async() =>{
    let totalPuntosMes = 0;
    let response = await getPuntosOrdenados(puntoFecha);
    response.data?.map((semana)=>{
        semana.dias.map((dia)=>{
          totalPuntosMes = totalPuntosMes + dia.puntoDetails.length
        })
    })
    setTotalPuntos(totalPuntosMes);
    setPuntosDeSemana(response.data)
    setLoading(false)
  }
  const getPuntosOfdayOfSemana = (semanaSelected,diaSelected) => {
    let puntos = []
    puntosDeSemana.forEach(semana=>{
      if(semana.semana ==semanaSelected ){
        semana.dias.forEach(dia=>{
          if(dia.dia == diaSelected){
            puntos = dia.puntoDetails
          }
        })
      }
    })
    return puntos;
  }
  const onClickDate = (semanaSelected,diaSelected) => {
    let puntos = getPuntosOfdayOfSemana(semanaSelected,diaSelected)
    setMapPuntos(puntos);
  }
  useEffect(()=>{
    if(selectedFecha.semana!=-1 && selectedFecha.dia != -1){
      let puntos = getPuntosOfdayOfSemana(selectedFecha.semana,selectedFecha.dia)
      setMapPuntos(puntos);
    }
  },[puntosDeSemana,selectedFecha])
  const breadcrumbLinks = [
    { href: `/itinerario/`, text: "Lista de itinerarios" },
    {
      href: `/itinerario/nuevo-itinerario`,
      text: "Seleccionar cartera",
    },
    {
      href: `/itinerario/nuevo-itinerario/seleccionar-pdvs`,
      text: "Seleccionar PDVs",
    },
    { text: "Confirmar", active: true },
  ];
  const removePunto = (puntoId,semanaIndex,diaIndex) => {
    let puntosDeSemanaCopy = [...puntosDeSemana]
    let puntos = getPuntosOfdayOfSemana(semanaIndex,diaIndex)
    puntos = puntos.filter((punto) => punto.id != puntoId);
    puntosDeSemanaCopy = storePuntosInDiaInSemana(puntosDeSemanaCopy,puntos,semanaIndex,diaIndex)
    setSelectedFecha({semana:semanaIndex,dia:diaIndex})
    setPuntosDeSemana(puntosDeSemanaCopy);
  };
  const storePuntosInDiaInSemana = (puntosDeSemanaCopy,puntos,semanaIndex,diaIndex) => {
    puntosDeSemanaCopy.forEach(semana=>{
      if(semana.semana == semanaIndex){
        semana.dias.forEach(dia=>{
          if(dia.dia == diaIndex){
            dia.puntoDetails = puntos
          }
        })
      }
    })
    return puntosDeSemanaCopy
  }
  const onDragEnd = (result,selectedSemana,selectedDia) => {
    const { source, destination } = result;
    setSelectedFecha({semana:selectedSemana,dia:selectedDia})
    if (!destination) return;
    let puntosDeSemanaCopy = [...puntosDeSemana]
    let puntos = getPuntosOfdayOfSemana(selectedSemana,selectedDia)
    if(source.index < destination.index){
      puntos.forEach((punto,puntoIndex)=>{
        if(puntoIndex != source.index && puntoIndex<=destination.index){
          punto.orden = punto.orden - 1;
        }
        puntos[source.index].orden = destination.index + 1;
      }) 
    } else {
      puntos.forEach((punto,puntoIndex)=>{
          if(puntoIndex != source.index && puntoIndex>=destination.index){
            punto.orden = punto.orden + 1;
          }
          puntos[source.index].orden = destination.index + 1;
        })
    }
    puntosDeSemanaCopy = storePuntosInDiaInSemana(puntosDeSemanaCopy,puntos,selectedSemana,selectedSemana)
    puntos.sort((a,b)=>a.orden-b.orden);
    setMapPuntos([...puntos]);
    setPuntosDeSemana(puntosDeSemanaCopy);
  };
  const insertIntinerary = async() => {
    if(isEditItinerario){
      let response = await updateMonthlyItinerario(puntosDeSemana,merchandiser.id) 
      if(response.status == 200){
        navigate('/itinerario', {
          state: {
            status: response.status,
            text: response.status == 200
            ? "Se creo todos los itinerarios de este mes"
            : "No se pudo crear todos los itinerarios del mes. favor intentar de nuevo!"
          },
        });
      }
      return
    }
    let response = await monthlyItinerario(puntosDeSemana,merchandiser.id) 
    if(response.status == 200){
      navigate('/itinerario', {
        state: {
          status: response.status,
          text: response.status == 200
          ? "Se creo todos los itinerarios de este mes"
          : "No se pudo crear todos los itinerarios del mes. favor intentar de nuevo!"
        },
      });
    }
  };
  return (
    <Fragment>
      <Row>
        <div className="d-flex justify-content-between mb-4">
          <h3 className="fw-bold">{pageName}{" "}
          <strong style={{ color: "#000ECE" }}>{merchandiser.nombre}</strong></h3>
        </div>
        <Row style={{ marginBottom: 10 }}>
          <BreadcrumbItem breadcrumbLinks={breadcrumbLinks} merchan={true} />
        </Row>
      </Row>
      <Row className="position-relative m-0">
        <div style={{ width: "50%", padding: 0 }}>
            <Card
                className={`card-container middle`}
                style={{
                    height: "70vh",
                    border: "1px solid #9DBCF9",
                    margin: "0",
                }}
            >
                <Card
                    className={'middle'}
                    style={{
                    padding: "24px 16px",
                    height: "auto",
                    margin: "0",
                    borderBottom: "1px solid #9DBCF9",
                    }}
                >
                    <p style={{ margin: "0", color: "#4D4D4D", fontSize: "20px", height:"20px" }}>
                        <Fragment>
                            <div className="d-flex justify-content-between align-items-center">
                            <span style={{ color: "blue", fontWeight: "bolder" }}>
                                Ruta / Itinerario
                            </span>
                            <span style={{ fontSize: "16px" }}>{totalPuntos} PDVs</span>
                            </div>
                        </Fragment>
                    </p>
                </Card>
                <Card.Body
                    className="p-0"
                    style={{ overflowX: "hidden", overflowY: "auto" }}
                >
                    <div
                    style={{
                    listStyleType: "none",
                    textAlign: "left",
                    padding: "0%",
                    width: "100%",
                    }}
                    >
                        <h6
                        className="px-3 my-4 fw-bold"
                        style={{ color: "#000061", fontSize: "16px" }}
                    >
                        Paradas
                    </h6>
                    </div>
                    <Accordion defaultActiveKey="0">
                        {puntosDeSemana.map((semana,semanaIndex)=>{
                          {return semana && (
                            <Accordion.Item
                            key={`accordionSemanaItem-${semanaIndex}`}
                             eventKey={semanaIndex} style={{
                              backgroundColor: "white",
                              border:"1px solid rgba(0,0,0,.125)!important"
                            }}>
                                <Accordion.Header key={`accordionSemanaHeader-${semana}`}>Semana {semana.semana}</Accordion.Header>
                                <Accordion.Body>
                                    <Accordion defaultActiveKey="0">
                                      {semana.dias.map((dia,diaIndex)=>{
                                            return (
                                                <Accordion.Item 
                                                      key={`accordionDia-${dia.dia}`}
                                                      eventKey={diaIndex} 
                                                      onClick={()=>onClickDate(semana.semana,dia.dia)}
                                                      style={{
                                                        backgroundColor: "white",
                                                        border:"1px solid rgba(0,0,0,.125)!important"
                                                      }}>
                                                    <Accordion.Header key={`accordionDiaHeader-${dia.dia}`}>
                                                    <div
                                                      className="d-flex justify-content-between w-100">
                                                        <div className="d-flex flex-column">
                                                        {days.find(day=>day.number==dia.dia).title}
                                                        </div>
                                                        <p style={{ fontSize: "16px",fontWeight:"300" }} className="d-flex align-items-end">{dia.puntoDetails.length} PDVs</p>
                                                    </div>
                                                      
                                                      </Accordion.Header>
                                                    <Accordion.Body
                                                                  key={`accordionpunto-${dia.dia}`}
                                                                   style={{
                                                                        padding: "0",
                                                                        border:"1px solid rgba(0,0,0,.125)!important",
                                                                        backgroundColor: "white",
                                                                        paddingTop:"5px",
                                                                        paddingLeft:"12px"
                                                                      }}>
                                                            <Fragment>
                                                            <DragDropContext onDragEnd={(e)=>onDragEnd(e,semana.semana,dia.dia)}>
                                                              <DragDropComponent
                                                                    paradas={dia.puntoDetails}
                                                                    deleteFunction={(e)=>removePunto(e,semana.semana,dia.dia)}
                                                                />
                                                            </DragDropContext>
                                                                
                                                            </Fragment>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })}
                                        
                                    </Accordion>
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                            
                        })}
                    </Accordion>
                </Card.Body>
                
            </Card>
        </div>
        <div style={{ width: "50%", padding: 0}}>
          <div
            className="p-5 bg-white h-100"
            style={{ border: "1px solid #9DBCF9", borderLeft: 0 }}
          >
            <ItinerarioMap
              puntos={mapPuntos}
              center={{ lat: -25.284521262221805, lng: -57.58740406052599 }}
            ></ItinerarioMap>
          </div>
        </div>
        <div
           style={{ position: "absolute", bottom: "-25px" }}
        >
          <ColumnFooter
             addressLink=""
             btnText="CONFIRMAR"
             changeFooterTypeTo={2}
             currentFooterType={2}
             footerType={2}
             onEvent={insertIntinerary}
             style={{ bottom: "-60px !important" }}
          />
        </div>
      </Row>
      { loading &&
      <div style={{position: "fixed",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      zIndex:"100",
      backgroundColor: "#F8F8F8AD"}}>
        <Spinner animation="border" variant="primary" role="status" >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        
      </div>
      }
      
    </Fragment>
  );
});
const styles = {
    "accordion-item": {
      backgroundColor: "white",
      border:"1px solid rgba(0,0,0,.125)!important"
    },
    "accordion-body": {
      padding: "0",
      border:"1px solid rgba(0,0,0,.125)!important",
      backgroundColor: "white",
      paddingTop:"5px",
      paddingLeft:"12px"
    },
  };
ConfirmarMonthlyItinerario.displayName = "ConfirmarItinerario";
export default ConfirmarMonthlyItinerario;
