import React, { Fragment, memo } from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./BreadcrumItem.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BreadcrumbItem = memo((props) => {
  const navigate = useNavigate()
  const { breadcrumbLinks, merchan } = props;

  
  const navegarHallazgoList = (categoriaId) => {    
    navigate('/epop/hallazgos/categoria_hallazgo',{state:{categoriaId:categoriaId}})
  }

  const userName = useSelector((store)=>{return store.appDatos.merchandiser?.Merchan})

  return (
    <Fragment>
      <Row className="d-flex justify-content-between">
        <Col>
          <Breadcrumb>
            {breadcrumbLinks.map((link) => (
              <Breadcrumb.Item
                as="li"
                key={`breadcrumb-${link.href}`}
                {...(link.active
                  ? { active: true, "aria-current": "page" }
                  : {})}
              >
                {link.active ? (
                  <span className="">{link.text}</span>
                ) : link.categoriaId ? (
                  <p onClick={()=>navegarHallazgoList(link.categoriaId)}>{link.text}</p>
                ) : (
                  <Link to={link.href} className="breadcrumb-link">
                    {link.text}
                  </Link>
                )}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Col>
        {merchan && (
          <Col className="px-0">
            <div
              className="d-flex justify-content-end text-dark align-items-center"
              style={{ fontSize: "16px" }}
            >
              Merchan:&nbsp;
              <span style={{ color: "blue", fontWeight: "bolder" }}>
                {userName}
              </span>
            </div>
          </Col>
        )}
      </Row>
    </Fragment>
  );
});

export default BreadcrumbItem;
