import { useEffect } from "react";
import { IndexRouters } from "./router/index";

import "shepherd.js/dist/css/shepherd.css";
import "flatpickr/dist/flatpickr.css";
import "choices.js/public/assets/styles/choices.min.css";
import "./assets/scss/hope-ui.scss";
import "./assets/scss/pro.scss";
import "./assets/modules/chat/scss/chat.scss";
import "./assets/custom/scss/custom.scss";
import "swiper/css";
import "swiper/css/navigation";

import { useDispatch } from "react-redux";
import { setSetting } from "./store/setting/actions";

function App() {
  const dispatch = useDispatch();
  dispatch(setSetting());
  
  return (
    <div className="App">
      <IndexRouters />
    </div>
  );
}

export default App;
