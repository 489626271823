import apiClient from "./AxiosRequest";

export const showPedidos =  (page, tiempo, selectedTabId) => {
    //console.log('soy selectedTabId ', selectedTabId)
    const tab = {
        tipo_tabla: selectedTabId
    }
    if(page){
        return apiClient().post('/solicitud_punto_merchandiser/' + tiempo +'/?page='+page, tab)
    }
    return apiClient().post('/solicitud_punto_merchandiser/' + tiempo, tab)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const aceptarPedido =  (pedido) => {
    return apiClient().post('/accept_pedido/'+ pedido)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const rechazarPedido =  (pedidos, comentarios) => {
    let rechazado = {
        pedido:pedidos,
        comentario:comentarios
    }
    return apiClient().post('/rechazar_pedido/', rechazado )
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const aceptarMuchosPedidos =  (pedido) => {
    return apiClient().post('/aceptar_muchos_pedido/', pedido)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const rechazarMuchosPedidos =  (pedido, comentarios) => {
    const rechazados = {
        comentario: comentarios,
        pedidos: pedido
      }
    return apiClient().post('/rechazar_muchos_pedido', rechazados)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const buscarPedido =  (cancel,texto,page=null) => {
    if(page){
        return apiClient().get('/buscar_pedido/' + texto + '?page=' + page , {cancelToken:cancel?.token})
    }
    return apiClient().post('/buscar_pedido/'+ texto, {cancelToken:cancel?.token})
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const showHistorial =  (tiempo) => {
    return apiClient().post('/historial_pedido/'+ tiempo)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const filterSolicitud =  (filtro, tiempo) => {
    if (filtro.length != 0){
        let fields = {
            merchandiser_id: filtro.merchandisers,
            tiempo: tiempo.toString()
        }
        console.log('filtro[0].merchandisers', filtro.merchandisers,)
        console.log('tiempo.toString()', tiempo.toString())
        console.log('soy fields de filter', fields)
        return apiClient().post('/filtro_pedidos', fields)
        .catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log('auth data: ', error.response.data);
                console.log('status', error.response.status);
                console.log('headers', error.response.headers);
                return error.response.status;
            } else if (error.request) {
                // The request was made but no response was received
                console.log("login error", error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error login 2', error.message);
            }
        });
    }
}