import apiClient from "./AxiosRequest";
export const loginCall =  (data) => {
    //console.log('desde AuthDataService:',data)
    return apiClient().post('/login_user',data)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("login error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error login 2', error.message);
        }
    });
}
export const checkUserToken = (data) => {
    return apiClient().get('/user',data)
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("check user error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('check user error 2', error.message);
        }
    });
}
export const getCorporacion = () =>{
    return apiClient().get('/get_corporacion')
    .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log('auth data: ', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
            return error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            console.log("check user error", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('check user error 2', error.message);
        }
    });
}