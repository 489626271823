
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "components/Table/index";
import './PedidoDetailModal.css'
import { updatePedido } from "dataservice/PedidoTransferDataService";

const columns = [ {label: "Producto", sortable: true },
                {label: "Cantidad", sortable: true }]
const PedidoDetailModal = (props) => {
    const { pedido,handleClose,show,mode } = props;
    const [productos,setProductos] = useState([])
    useEffect(()=>{
        if(pedido){
            let productos = pedido?.pedido_producto_transfer.map((producto)=>{
                return({id:producto.producto_transfer.id,Producto:producto.producto_transfer.name,Cantidad:producto.cantidad})
            })
            setProductos(productos)
        }
    },[pedido])
    const aceptarPedido = async() => {
        const response = await updatePedido("APROBADO",pedido.id)
        if(response?.status===200){
            handleClose({message:"El pedido fue aprobado exitosamente",type:'success'});
        }
        else{
            handleClose({message:"Ocurrió un error al aprobar el pedido",type:'danger'});
        }
    }
    const rechazarPedido = async() => {
        const response = await updatePedido("RECHAZADO",pedido.id)
        if(response?.status===200){
            handleClose({message:"El pedido fue rechazado exitosamente",type:'success'});
        }
        else{
            handleClose({message:"Ocurrió un error al rechazar el pedido",type:'success'});
        }
    }
    return (
        <>
        <Modal show={show} onHide={handleClose} dialogClassName="my-modal-container">
            <Modal.Header closeButton>
            <Modal.Title>Detalle del pedido</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>PDV:</strong> {pedido?.itinerario_punto.punto_merchandiser.punto.nombre}</p>
                <p><strong>Merchandiser:</strong> {pedido?.itinerario_punto.punto_merchandiser.merchandiser.nombre}</p>
                <p><strong>Estado:</strong> {pedido?.estado}</p>
                <p><strong>Fecha pedido:</strong> {pedido?.fecha}</p>
                <p><strong>Productos:</strong> {pedido?.fecha_entre}</p>
                <Table
                    data={productos}
                    columns={columns}
                    selectAll={true}
                    firstColumnColor={true}
                    underlineFirstColumn={true}
                    border={true}
                    title={"Productos"}
                    link={null}
                    />
            </Modal.Body>
            <Modal.Footer>
            {mode=='EDIT' &&
            <div>
            <Button
                variant="primary rounded-pill"
                onClick={()=>rechazarPedido()}
                style={{
                    border: "1px solid #000ece",
                    color: "#000ece",
                    background: "white",
                    marginRight: 10
                }}
                >
                Rechazar
            </Button>
            <Button variant="primary rounded-pill" onClick={()=>aceptarPedido()}>
                Aprobar
            </Button>
            </div>}
            
            
            </Modal.Footer>
        </Modal>
        </>
    );
}
export default PedidoDetailModal;